const { subAreas } = require('../utils/subareas')

module.exports = {
  pt: {
    emailOrPassword: 'Verifique seu email/senha',
    emailNotConfirmed: 'O seu e-mail de acesso não foi confirmado. Por gentileza, acesse seu e-mail e confirme para acessá-lo.',
    myPanelFairVacancies: 'Meu painel de feira',
    forgotPassword: 'Esqueceu sua senha?',
    clickHereLogin: 'Clique aqui',
    toRecoveryLogin: 'para recuperá-la',
    institutionTitle: 'Dados da instituição / escola',
    institutionFairTitle: 'Dados da instituição/feira',
    cell: 'Celular',
    landline: 'Telefone fixo (opcional)',
    email: 'E-mail',
    enrollmentInProgress: 'Sua inscrição ainda não foi finalizada. Dê andamento ao seu cadastro',
    pendingAnalysis: 'Aguarde. Sua solicitação de inscrição foi recebida por nosso sistema e será revisada pelo Comitê de Revisão Científica - CRC em seguida.',
    inAnalysis: 'Aguarde. Sua solicitação de inscrição está em análise e logo iremos retornar com a resposta via email.',
    notAcceptedText: 'Agradecemos o interesse em participar da MOSTRATEC-LIBERATO. Infelizmente, seu projeto não foi selecionado. Você pode tentar novamente no próximo ano.',
    contact: 'Contato',
    mainContactInfo: 'As informações do contato 1 serão utilizadas para notificações referente à MOSTRATEC.',
    bestEmail: 'Melhor email - (informe um e-mail que seja acessado frequentemente, pois essa será a forma de contato com a feira afiliada).',
    contacts: 'Contatos',
    name: 'Nome',
    buttonLogin: 'Entrar',
    rememberMeLogin: 'Lembrar de mim',
    emailLogin: 'insira seu e-mail',
    passwordLogin: 'insira sua senha',
    passwordLoginLabel: 'Senha',
    abstract: 'Resumo',
    projectSummary: 'Resumo do projeto',
    ResearchPlan: 'Plano de Pesquisa',
    ResearchPlanInfo: 'Os campos a seguir devem conter uma descrição concisa e objetiva dos elementos referentes ao seu plano de pesquisa',
    ThemeLabel: 'Tema',
    ThemePlaceholder: 'Insira o tema da pesquisa',
    ThemeTextInfo: 'Descrever o aspecto do assunto que se deseja abordar, provar ou desenvolver',
    JustificationLabel: 'Justificativa',
    JustificationPlaceholder: 'Insira a justificativa da pesquisa',
    JustificationCancel: 'Insira a justificativa de cancelamento',
    JustificationTextInfo_parOne: 'A Justificativa, num projeto de pesquisa, é o convencimento de que o trabalho de pesquisa é fundamental de ser efetivado e relevante para a sociedade ou para alguns indivíduos que se beneficiarão com a pesquisa. Deve apresentar como as informações geradas pela pesquisa são úteis e a quem, como irá agregar e que decisões poderão ser tomadas a partir dos dados gerados.',
    JustificationTextInfo_parTwo: 'A justificativa exalta a importância do tema a ser estudado, justifica a necessidade de se levar a efeito a realização de tal empreendimento e encaminha para a formulação do problema. Deve ser ressaltado no trabalho que existem outros trabalhos que evidenciam a importância do tema da pesquisa e estes devem ser referenciados.',
    ProblemLabel: 'Problema',
    ProblemPlaceholder: 'Insira o problema da pesquisa',
    ProblemTextInfo: 'O problema tem como origem uma situação que provoca questões sobre o tema e pode ser definido pela própria vivência do pesquisador ou indicado por profissionais ligados ao tema. A partir da identificação do problema, elabora-se uma questão específica a ser respondida pela pesquisa, ficando assim estabelecido um foco de estudo para responder a questão. As questões de pesquisa devem ser passíveis de respostas as quais devem ser obtidas com metodologia científica e/ou tecnológica/de engenharia.',
    HypothesisLabel: 'Hipóteses (opcional)',
    HypothesisPlaceholder: 'Insira a hipótese da pesquisa',
    HypothesisTextInfo_parOne: 'A hipótese é uma possível resposta à questão estabelecida no problema do projeto de pesquisa. Segundo Bello (2009, p. 23), é uma pré-solução para o problema levantado no tema escolhido para a pesquisa.',
    HypothesisTextInfo_parTwo: 'Dependendo da opção metodológica feita, a pesquisa não precisará obrigatoriamente estabelecer hipóteses. Para pesquisas do tipo levantamento ou pesquisas tecnológicas, por exemplo, hipótese não se torna um item formal obrigatório.',
    ObjectivesLabel: 'Objetivos',
    ObjectivesPlaceholder: 'Insira os objetivos da pesquisa',
    ObjectivesTextInfo: 'A definição dos objetivos determina o que o pesquisador quer atingir com a realização do trabalho de pesquisa e devem corresponder às questões propostas.',
    ObjectLabel: 'Objetos',
    ObjectPlaceholder: 'Insira os objetos da pesquisa',
    ObjectTextInfo: 'É o que será feito. A partir do objeto é definida a área da pesquisa e a classificação em pesquisa científica ou tecnológica.',
    BibliographicReviewLabel: 'Revisão bibliográfica',
    BibliographicReviewPlaceholder: 'Insira a revisão bibliográfica da pesquisa',
    BibliographicReviewTextInfo: 'Neste momento, o pesquisador busca, localiza e revisa a literatura onde obterá material bibliográfico que subsidiará o tema do trabalho de pesquisa, tais como livros, artigos científicos, revistas, jornais, normas técnicas, legislação, etc.',
    MethodologyLabel: 'Metodologia',
    MethodologyPlaceholder: 'Insira a metodologia da pesquisa',
    MethodologyTextInfo_parOne: 'A Metodologia, que também é chamada de Materiais e Métodos, é a descrição da estratégia a ser adotada, onde constam todos os passos e procedimentos adotados para realizar a pesquisa e atingir os objetivos. ',
    MethodologyTextInfo_parTwo: 'Depois de realizar as opções, deve ser feita a descrição detalhada de como será feita a pesquisa (como os dados serão coletados, questionários, entrevistas, amostras e etc) e de como será feita a análise dos dados que serão obtidos. Deve ser incluído o cronograma, os recursos que serão necessários e a avaliação.',
    CronogramLabel: 'Cronograma',
    CronogramPlaceholder: 'Insira o cronograma da pesquisa',
    CronogramTextInfo: 'É um planejamento adequado do tempo que o pesquisador terá para realizar o trabalho, especificando as atividades a serem cumpridas.',
    DataAnalisisLabel: 'Análise de dados',
    DataAnalisisPlaceholder: 'Insira a análise de dados da pesquisa',
    DataAnalisisTextInfo: 'Deve mostrar como será feita a análise, avaliação dos dados, com o que vai comparar, vai usar algum método estatístico? Como vai poder dizer se os resultados estão bons ou não, vai comparar os resultados com o quê, com especificações de alguma norma ou com os resultados de algum autor, ou com que outro tipo de pesquisa?',
    ReferencesLabel: 'Referências',
    ReferencesPlaceholder: 'Insira as referências da pesquisa',
    ResultsLabel: 'Resultados e conclusões',
    ReferencesTextInfo: 'A referência dos documentos efetivamente citados dentro do projeto da pesquisa é um item obrigatório para a elaboração do Projeto.',
    affiliationPeriodClosed: 'Período de afiliação encerrado!',
    ifYouMissedTheDeadline: 'Se você perdeu o prazo, contamos com sua colaboração para incentivar a pesquisa científica nos estudantes na próxima edição da Mostratec!',
    projectData: 'Dados do projeto',
    projectTitle: 'Título do projeto',
    enterTheProjectTitle: 'Insira o título do projeto',
    continuityFileInsert: 'Insira o documento de continuidade',
    typeOfResearchDevelop: 'Tipo da pesquisa desenvolvida',
    researchAreaSingle: 'Área de pesquisa',
    researchSubAreas: 'Subáreas de pesquisa',
    requestRegistration: 'Solicitar Inscrição',
    noProject: 'Você ainda não possui nenhum projeto inscrito na Mostratec. Atente-se aos prazos e taxa de inscrição e clique em Solicitar inscrição para começar!',
    isThereOtherSubarea: 'Existe outra sub-área?',
    isThereOtherSubareaPlaceholder: 'Se sim, insira qual é a outra sub-área',
    isYourProjectContinuity: 'Seu projeto é de continuidade?',
    continuityProjectInfo: 'Se o projeto de até 12 meses foi concluído e você decidiu dar seguimento a ele com um novo objetivo, isto é considerado projeto de continuidade.',
    doesItInvolveVertebrateAnimals: 'Envolve animais vertebrados não humanos?',
    doesItInvolveHumans: 'Envolve seres humanos?',
    doesItInvolveHumansInfo: 'Se a sua pesquisa envolveu seres humanos, como questionário, entrevista, outros tipos de interação, etc, é necessário preencher o formulário 4, assinar todos os campos conforme indicado no formulário e anexar ao final da inscrição.',
    doesItInvolveBiologicalSubstances: 'Envolve substâncias biológicas?',
    doesItInvolveDangerousBiologicalSubstances: 'Envolve substâncias biológicas potencialmente perigosas?',
    doesItInvolveTissueFromHumans: 'Envolve tecidos de seres humanos ou de animais vertebrados?',
    doesItInvolveControlledSubstances: 'Envolve substâncias controladas?',
    doesItInvolveDangerousSubstances: 'Envolve substâncias ou equipamentos perigosos?',
    isTheResearchCarriedInInstituition: 'A Pesquisa é feita em instituição de pesquisa ou indústria regulamentada?',
    isTheResearchCarriedInInstituitionInfo: 'Exemplo: universidade, laboratório, etc.',
    scientific: 'Científica',
    technological: 'Tecnológica',
    scientificInfo: 'Básica ou Aplicada e Pesquisa de engenharia.',
    technologicalInfo: 'Pesquisa de Engenharia - Materialização de um produto ou processo através de um modelo, protótipo ou instalação piloto. Execução de uma obra, fabricação de um produto, fornecimento de um serviço, execução de um processo.',
    cellularMolecularMicrobiology: 'Biologia Celular e Molecular, Microbiologia',
    animalPlantSciences: 'Ciências Animais e de Plantas',
    environmentalSciences: 'Ciências Ambientais',
    healthSciences: 'Ciências da Saúde',
    educationAndHumanities: 'Educação e Humanidades',
    environmentalAndSanitaryEngineering: 'Engenharia Ambiental e Sanitária',
    electricalEngineering: 'Engenharia Elétrica',
    electronicEngineering: 'Engenharia Eletrônica',
    mechanicalEngineering: 'Engenharia Mecânica',
    historyAndSocialSciences: 'História e Ciências Sociais',
    checkResearchSubAreas: 'Assinale as subáreas de pesquisa do seu projeto:',
    molecularAndCellBiology: 'Biologia molecular e celular',
    genetics: 'Genética',
    immunology: 'Imunologia',
    antibiotics: 'Antibióticos',
    antimicrobials: 'Antimicrobianos',
    bacteriology: 'Bacteriologia',
    virology: 'Virologia',
    mycology: 'Micologia',
    others: 'Outras',
    generalBiochemistry: 'Bioquímica geral',
    metabolism: 'Metabolismo',
    foodChemistry: 'Química dos alimentos',
    organicChemistry: 'Química orgânica',
    inorganicChemistry: 'Química inorgânica',
    analyticalChemistry: 'Química analítica',
    generalChemistry: 'Química geral',
    chemicalEngineering: 'Engenharia química',
    physicoChemical: 'Físico-química',
    structuralBiochemistry: 'Bioquímica estrutural',
    biologicalDevelopment: 'Desenvolvimento biológico (humano e animal)',
    ecology: 'Ecologia',
    animalHusbandry: 'Criação de animais',
    animalPhysiologyPathology: 'Fisiologia e patologia animal',
    agricultureAgronomy: 'Agricultura/agronomia',
    plantMorphology: 'Morfologia e desenvolvimento das plantas',
    photosynthesis: 'Fotossíntese',
    plantPhysiology: 'Fisiologia das plantas',
    plantEvolutionTheory: 'Teoria de evolução das plantas',
    pollutionAir: 'Poluição e qualidade do ar',
    pollutionWater: 'Poluição e qualidade da água',
    contaminationSoilQuality: 'Contaminação e qualidade do solo',
    environmentalToxicology: 'Toxicologia Ambiental',
    computerEngineering: 'Engenharia da Computação',
    algorithmsDatabase: 'Algoritmos, base de dados',
    artificialIntelligence: 'Inteligência Artificial',
    communicationNetworks: 'Comunicação e redes',
    computerScienceSub: 'Ciência Computacional',
    computacionalSciences: 'Ciências da Computação',
    graphics: 'Gráficos',
    softwareEngineering: 'Engenharia de Software',
    computerSystems: 'Sistemas de computadores',
    programmingLanguages: 'Linguagens de Programação',
    diagnosisAndTreatmentDiseases: 'Diagnóstico e tratamento de doenças',
    epidemiology: 'Epidemiologia',
    physiologyAndPathophysiology: 'Fisiologia e Fisiopatologia',
    healthAtWork: 'Saúde no Trabalho',
    preventionAndProphylaxis: 'Prevenção, profilaxia',
    nutrition: 'Nutrição',
    biomedicine: 'Biomedicina',
    nursing: 'Enfermagem',
    dentistry: 'Odontologia',
    physicalEducation: 'Educação Física',
    climatology: 'Climatologia, clima',
    geochemistryGeophysics: 'Geoquímica, Geofísica',
    mineralogy: 'Mineralogia',
    paleontology: 'Paleontologia',
    geology: 'Geologia',
    seismology: 'Sismologia',
    oceanography: 'Oceanografia',
    algebra: 'Álgebra',
    analyticalMathematics: 'Matemática analítica',
    aplicatedMath: 'Matemática aplicada',
    geometry: 'Geometria',
    probabilityStatistics: 'Probabilidade e Estatística',
    atomsMoleculesSolids: 'Átomos, moléculas e sólidos',
    biologicalPhysics: 'Física Biológica',
    instrumentationEletronics: 'Instrumentação e eletrônica',
    magnetismElectromagnetism: 'Magnetismo e Eletromagnetismo',
    particleNuclearPhysics: 'Física de partículas e nuclear',
    opticsLaser: 'Óptica, Laser',
    acoustics: 'Acústica',
    theoreticalPhysicsAstronomy: 'Física teórica, astronomia',
    theories: 'Teorias do conhecimento, Metodologia, Didática, Pedagogia',
    linguistics: 'Linguística',
    artisticExpression: 'Expressão Artística',
    philosophyTheology: 'Filosofia e Teologia',
    bioremediation: 'Biorremediação',
    ecosystemManagement: 'Gerenciamento de ecossistemas',
    landWaterForestManagement: 'Gerenciamento de recursos terrestres, hídricos e florestais',
    wasteManagement: 'Gerenciamento de resíduos, reciclagem',
    environmentalPolicy: 'Política ambiental, legislação ambiental',
    environmentalEducational: 'Educação ambiental',
    transportEngineering: 'Eng. dos transportes',
    aerospaceAeronauticalEngineering: 'Eng. aeroespacial e aeronáutica, aerodinâmica',
    civilEngineering: 'Eng. civil e construção',
    securityEngineering: 'Eng. de Segurança',
    productDevelopment: 'Desenvolvimento de produtos',
    materialsScience: 'Ciência dos materiais',
    generalEletric: 'Elétrica Geral',
    generationTransmissionElectricalEnergy: 'Geração, transmissão e transformação de energia elétrica',
    eletricDrives: 'Acionamentos elétricos',
    renewableEnergy: 'Energias Renováveis',
    generalElectronics: 'Eletrônica Geral',
    electronicSystems: 'Sistemas e Processos Eletrônicos',
    communicationSystems: 'Sistemas de Comunicação',
    industrialAutomation: 'Automação Industrial',
    robotics: 'Robótica',
    industrialEngineering: 'Engenharia industrial',
    vehicleDevelopment: 'Desenvolvimento de veículos',
    productAndProcessDevelopment: 'Desenvolvimento de produtos e processos',
    history: 'História',
    psychology: 'Psicologia',
    sociology: 'Sociologia',
    anthropology: 'Antropologia',
    economy: 'Economia',
    policts: 'Política',
    management: 'Administração',
    informTheDataOfTheProject: 'Informe os dados dos autores deste projeto. O número de estudantes disponível para cadastro corresponde à sua resposta no campo',
    numberOfStudentsInTheProject: 'Quantidade de estudantes no projeto.',
    ableToParticipateInALivePresentation: 'O(s) estudante(s) do projeto poderão participar de uma apresentação ao vivo?',
    individual: 'Individual',
    pair: 'Dupla',
    trio: 'Trio',
    studentOneInfo: 'O Estudante 1 é aquele responsável pelo pré-cadastro e pela inscrição do projeto. Lembre-se que é através do login do pré-cadastro deste usuário que será possível acompanhar o andamento de sua inscrição.',
    fullName: 'Nome completo',
    fullNameStudent: 'Nome completo do estudante',
    fullNamePlaceholder: 'Nome e sobrenome',
    maleSingle: 'Masculino',
    femaleSingle: 'Feminino',
    sex: 'Sexo',
    birthDate: 'Data de nascimento',
    responsibleName: 'Nome do(a) responsável',
    responsiblePerson: 'Responsável',
    relationshipOfResponsible: 'Parentesco do(a) responsável',
    enterThePhone: 'Insira o telefone',
    enterTheEmail: 'Insira o email do aluno',
    enterTheResponsibleAdultName: 'Insira o nome do adulto responsável',
    enterParentRelationship: 'Insira o parentesco do responsável',
    registrationData: 'Dados de inscrição',
    levelOfKnowledgeInEnglish: 'Nível de conhecimento em língua inglesa',
    basic: 'Básico',
    intermediary: 'Intermediário',
    advanced: 'Avançado',
    fluent: 'Fluente',
    doYouHaveScholarship: 'É bolsista de algum programa de iniciação científica júnior?',
    whichProgram: 'Qual programa?',
    haveYouParticipatedAnyScienceFair: 'Já participou de alguma feira de ciências?',
    doYouHaveAnyKindOfDisability: 'Possui algum tipo de deficiência?',
    none: 'Nenhuma',
    physical: 'Física',
    intellectual: 'Intelectual',
    auditory: 'Auditiva',
    visual: 'Visual',
    willYouNeedAnySpecializedAssistance: 'Necessitará algum atendimento especializado?',
    architecturalAccessibility: 'Acessibilidade arquitetônica (O espaço físico da feira já possui rampas de acesso e elevador)',
    libraInterpreter: 'Intérprete de Libras',
    reader: 'Ledor',
    othersMale: 'Outros;',
    typeOfShirt: 'Opta por qual tipo de camiseta?',
    whatSizeShirt: 'Qual o tamanho de camiseta?',
    scholarshipCNPq: 'Bolsa custeada pelo CNPq',
    otherFemale: 'Outra',
    enterTheProgramName: 'Insira o nome do programa',
    enterWhichScienceFair: 'Insira qual feira de ciências você já participou',
    yesOfTheMostratec: 'Sim, da Mostratec',
    notApplicable: 'Não se aplica',
    shirt: 'Camiseta',
    poloShirt: 'Camisa polo',
    p: 'P',
    m: 'M',
    g: 'G',
    gg: 'GG',
    projectsRevision: 'Revisão do projeto',
    projectsPayment: 'Pagamento de inscrição',
    isAproved: 'Você considera o projeto aprovado para participação na mostratec?',
    ifAproved: 'Caso aprovado, será permitido que os estudantes inscritos paguem a taxa de participação. Do contrário, insira uma justificativa para a reprovação nos campos de pareceres.',
    subscriptionAproved: 'Sim, inscrição aprovada!',
    subscriptionRejected: 'Não, inscrição reprovada!',
    revision: 'Revisão',
    reviewForm1: 'Formulário de revisão 1',
    startReview: 'Iniciar revisão',
    finishReview: 'Finalizar revisão',
    editSituation: 'Editar situação',
    video: 'Vídeo',
    revisionName: 'Nome do responsável pela revisão',
    nameSurname: 'Nome Sobrenome',
    opinionToInternal: 'Parecer registrado internamente ao sistema em caso de reprovação da inscrição do projeto',
    opinionToStudents: 'Parecer enviado aos estudantes em caso de reprovação da inscrição de seu projeto.',
    considerations: 'Deixe aqui suas considerações',
    projectGrade: 'Nota do projeto',
    giveAGrade: 'Dê uma nota de 0 a 10',
    continuity: 'Projeto de continuidade',
    presentation: 'Apresentação do trabalho',
    vertebrates: 'Animais vertebrados',
    bios: 'Substâncias biológicas potencialmente perigosas',
    scientist: 'Cientista qualificado',
    humans: 'Seres humanos',
    humansNecessaryFile: 'Necessário o upload do formulário de pesquisa com seres humanos',
    tissues: 'Substâncias com tecidos de seres humanos ou de animais vertebrados',
    substitute: 'Este projeto será suplente',
    riscs: 'Avaliação de riscos',
    banner: 'Banner',
    projectOpinion: 'Parecer ao projeto',
    internalOpinion: 'Parecer interno',
    revisionFiles: 'Revisão dos arquivos inscritos',
    useCheckbox: 'Para facilitar a sua organização, marque o checkbox dos arquivos revisados antes de aprovar o projeto.',
    returnInformationLogin: 'Retornar para a página de informações.',
    initialRegisterHeader1: 'Informe os seguintes dados para realizar o pré-cadastro na Mostratec',
    initialRegisterHeader2: 'Digital. Após preencher todos os campos, confirme seu cadastro acessando a',
    initialRegisterHeader3: 'caixa de e-mail do e-mail registrado no formulário abaixo. Depois da',
    initialRegisterHeader4: 'confirmação dos dados, você poderá acessar o sistema para preencher o',
    initialRegisterHeader5: 'formulário de inscrição e acompanhar o status da sua',
    initialRegisterHeader6: 'solicitação.',
    allFieldsAreObligatory: 'Todos os campos são obrigatórios.',
    contactNamePlaceholder: 'Insira o nome de contato',
    contactPhone: 'Telefone (é opcional ser fixo ou celular, somente números)',
    office: 'Cargo',
    officePlaceholder: 'Insira o cargo do contato',
    fairName: 'Nome da feira',
    institutionFairName: 'Nome da instituição/feira',
    country: 'País',
    state: 'Estado',
    city: 'Cidade',
    cpfCnpj: 'CPF/CNPJ',
    initialRegisterCpfCnpjTextInfo: 'Informe o CPF do aluno responsável pela inscrição de projetos.',
    initialRegisterEmailTextInfo: 'O email inserido acima também será utilizado para acessar a plataforma Mostratec após confirmação do pré-cadastro. Precisa ser diferente do email cadastrado na feira afiliada e não pode ser usado em mais de um projeto.',
    initialRegisterPasswordTextInfo: 'A senha criada acima será a senha utilizada para acessar a plataforma Mostratec após a confirmação do pré-cadastro. Precisa ter no mínimo 8 caracteres, incluindo letras minúsculas, maiúsculas, número e caracter especial (! @ # $ % & * / + - ?).',
    send: 'Enviar',
    reports: 'Relatórios',
    manageSubcriptions: 'Gerenciar inscrições',
    fairSettings: 'Configurações da feira',
    submitSubcriptions: 'Solicitar inscrição',
    notifications: 'Notificações',
    welcome: 'Bem-vindo(a)',
    heartyWelcome: 'Boas vindas!',
    editionRegistration: 'Cadastro de edição',
    feesDetails: 'Taxas',
    invalidEditionYear: 'O ano da início da feira deve ser maior ou igual ao ano de sua edição',
    allEditions: 'Todas as edições',
    generalData: 'Registrar edição',
    vacancies: 'Vagas',
    informations: 'Informações',
    editionInformations: 'Cadastre as informações solicitadas a seguir para configurar uma nova edição da feira Mostratec.',
    activeEditionWarn: 'Ao criar uma nova edição, ela será automaticamente definida como a edição ativa.',
    fairStatus: 'Status da feira',
    inactive: 'Inativa',
    active: 'Ativa',
    editionId: 'Código de identificação da edição',
    editionFairStatusInfo: 'Ao ativar, você está habilitando o recebimento de inscrições para a nova edição da feira cadastrada.',
    vinculatedFair: 'Feira vinculada',
    fair: 'Feira',
    documents: 'Documentos',
    researcherSummary: 'Resumo do projeto',
    involveds: 'Envolvidos',
    obrigatoryFiles: 'Arquivos obrigatórios',
    presentationVideo: 'Vídeo de apresentação',
    videoFile: 'Arquivo do vídeo',
    videoLink: 'Link do vídeo',
    bannerLink: 'Link do banner',
    projectBannerLink: 'Link do banner do projeto',
    projectVideoLink: 'Link do vídeo do projeto',
    placeholderBannerLink: 'Insira o link para o banner do projeto',
    videoLinkInsert: 'Insira o link da apresentação do projeto',
    editionVinculatedFairInfo: 'Selecione para qual modalidade de feira será configurada a nova edição.',
    editionVinculatedFairPlaceholder: 'Ex: Mostratec Júnior',
    editionName: 'Nome da edição',
    editionNamePlaceholder: 'Digite qual será o nome da edição',
    year: 'Ano',
    yearPlaceholder: 'Digite o ano da edição',
    format: 'Formato',
    editionFormatInfo: 'Selecione em qual formato ocorrerá a feira',
    inPerson: 'Presencial',
    virtual: 'Virtual',
    hibrid: 'Híbrida',
    reviewCanceled: 'Inscrição cancelada',
    reviewCanceledDescription: 'A inscrição foi cancelada pela secretaria.',
    taxPerParticipant: 'Taxa de inscrição por participante:',
    cause: 'Motivo',
    waitingPaymentText: 'Sua inscrição foi aceita pelo CRC! Para finalizar e participar da edição ativa da Mostratec, pedimos que realize o pagamento da taxa de inscrição.',
    affiliatedFairs: 'Feira afiliadas',
    editionAffiliatedFairDescription: 'Os campos a seguir são relacionadas aos períodos de inscrição que serão disponibilizados para as feiras afiliadas.',
    mostratecAffiliationRegistrationPeriod: 'Período de inscrição de afiliação à Mostratec',
    start: 'Início',
    end: 'Fim',
    mostratecAffiliationRegistrationPeriodInfo: 'Informe o período inicial e final do período de solicitação para as feiras afiliadas.',
    affiliatedFairsAnalysisPeriod: 'Período de análise das feiras afiliadas',
    affiliatedFairsAnalysisPeriodInfo: 'Informe o período inicial e final do período de análise das solicitações de afiliação.',
    affiliatedFairsResponsePeriod: 'Período de resposta as feiras afiliadas',
    affiliatedFairsResponsePeriodInfo: 'Informe o período inicial e final para responder as solicitações de afiliação.',
    mostratecProjectAfilliateFairRegistrationPeriod: 'Período de inscrição de projetos via feira afiliada à Mostratec',
    mostratecProjectSelectionRegistrationPeriod: 'Período de inscrição de projetos via processo de seleção à Mostratec',
    mostratecProjectSelectionRegistrationPeriodInfo: 'Informe o período inicial e final para inscrição de projetos via processo de seleção',
    mostratecProjectAfilliateFairRegistrationPeriodInfo: 'Informe o período inicial e final para inscrição de projetos via feira afiliada',
    PeriodInWhichMostratecEventWillTakePlace: 'Período em que ocorrerá o evento Mostratec',
    PeriodInWhichMostratecEventWillTakePlaceInfo: 'Informe o período inicial e final que o evento ocorrerá',
    save: 'Salvar',
    edit: 'Editar',
    trackEntries: 'Acompanhe suas inscrições.',
    subscriptionRequest: 'Solicitação de inscrição',
    selectionProcess: 'Processo de Seleção',
    appraisers: 'Avaliadores',
    allRequests: 'Todas as solicitações',
    openRequests: 'Solicitações em aberto',
    affiliatedFair: 'Feiras afiliada',
    acceptedRequests: 'Solicitações aceitas',
    rejectedRequests: 'Solicitações rejeitadas',
    search: 'Busca',
    fairEdition: 'Edição da feira',
    fairType: 'Tipo de feira',
    subscriptionRegistration: 'Inscrições',
    fairEditionPlaceholder: 'Busque as solicitações de feira por edição',
    fairTypePlaceholder: 'Ex: Mostratec Ens Fundamental, Mostratec Júnior',
    filter: 'Filtrar',
    subtitleDescription: 'Descrição das legendas:',
    pendingReview: 'Pendente de análise',
    underAnalysis: 'Em análise',
    notAccepted: 'Não aceita',
    accepted: 'Aceita',
    waitingAccept: 'Sua solicitação de inscrição foi enviada! Aguarde o retorno da feira afiliada',
    waitingResponse: 'Esperando resposta',
    code: 'Código',
    requestStatus: 'Status da solicitação',
    countryState: 'País/Estado',
    organizingInstitution: 'Instituição organizadora',
    schoolType: 'Tipo da escola',
    vacanciesNumberChildish: 'Nº vagas infantil',
    vacanciesNumberElementary: 'Nº vagas fundamental',
    vacanciesNumberHighSchool: 'Nº vagas médio/tec',
    form: 'Formulário',
    public: 'Pública',
    particular: 'Partiular',
    schoolData: 'Dados da escola',
    schoolInstitutionData: 'Dados da Instituição/escola',
    institutionName: 'Nome da instituição / escola',
    institutionNamePlaceholder: 'Insira o nome da instituição / escola',
    institutionResponsibleName: 'Nome do responsável da instituição / escola',
    institutionResponsibleNamePlaceholder: 'Insira o nome do responsável da instituição / escola',
    educationalInstitutionCategory: 'Categoria de ensino',
    private: 'Privada',
    emailContactPlaceholder: 'Insira o email de contato',
    next: 'Próximo',
    address: 'Endereço',
    zipCode: 'CEP',
    uf: 'UF',
    number: 'Número',
    numberOptional: 'Número (Opcional)',
    numberPlaceholder: 'Ex: 274',
    complement: 'Complemento',
    complementOptional: 'Complemento (Opcional)',
    complementPlaceholder: 'Ex: Ao lado do supermercado',
    back: 'Voltar',
    site: 'Site',
    sitePlaceholder: 'Insira a url do site',
    fairCoordenatorName: 'Nome do coordenador da feira',
    fairCoordenatorNamePlaceholder: 'Insira o nome do coordenador da feira',
    currentFairFairTypePlaceholder: 'Ex: Escolar',
    currentFair: 'Feira Vigente',
    lastFairEdition: 'Última edição da feira',
    lastFairEditionData: 'Dados da última edição da feira afiliada',
    yearly: 'Anual',
    biennial: 'Bienal',
    triennial: 'Trienal',
    fairStatusChange: 'Alteração de Status da Feira',
    fairStatusChangeWarning: 'Atenção! Você está alterando o status ativo/inativo dessa edição da feira! Ao ativar, você está habilitando o recebimento de inscrições para essa edição. Ao inativar, você está desabilitando o recebimento de inscrições para essa edição.',
    affiliationRequestModalSendFormTitle: 'Tudo pronto para enviar o formulário de solicitação de afiliação?',
    affiliationRequestModalSendFormText: 'Você chegou ao fim do preenchimento da inscrição do seu projeto. Orientamos que revise suas informações, pois ao enviar sua solicitação não será mais possível altera-los.',
    formSelectionRequestModalSendFormTitle: 'Tudo pronto para enviar o formulário de inscrição?',
    formSelectionRequestModalSendFormText: 'Você chegou o fim do preenchimento da inscrição de seu projeto. Orientamos que revise suas informações, pois ao enviar sua solicitação não será mais possível alterá-la (Exceto pelo resumo do projeto, que poderá ser alterado até o fim do prazo de inscrição).',
    yesContinue: 'Sim, continuar',
    noCancel: 'Não, cancelar',
    failedToSendEmail: 'Falha ao enviar e-mail',
    regulament: 'Regulamento',
    regulamentLink: 'https://www.mostratec.com.br/regulamento-da-mostratec/',
    enter: 'Entrar',
    dataGeneral: 'Dados gerais',
    vacanciesDetails: 'Vagas',
    areYouSureTitle: 'AVISO: Você tem certeza disso?',
    areYouSureText: 'Ao confirmar essa mensagem, todos os dados de participação da feira afiliada serão excluídos e a feira será desativada da Mostratec.',
    weakPassword: 'Senha fraca!',
    passwordsDontMatch: 'As senhas informadas não coincidem!',
    invalidEmail: 'Email inválido!',
    invalidZipCode: 'CNPJ/CPF inválido!',
    term: 'Termo de Consentimento para Tratamento de Dados Pessoais',
    advertisingGrant: 'Concessão de publicidade:',
    dateError: 'Data fim deve ser maior ou igual a data início',
    nextFairError: 'A data da próxima edição da feira deve ser maior que a data atual.',
    lastFairError: 'A data da última edição da feira deve ser menor que a data atual.',
    levelOfEducationError: 'Assinale ao menos um nível de ensino',
    invalidDateFormat: 'Formato de data inválido',
    removeContact: 'Ao confirmar essa mensagem, todos os dados do contato serão excluídos.',
    sendEmailFairText: 'Ao confirmar essa mensagem, um e-mail será enviado para essa feira afiliada com o resultado da análise.',
    submit: 'enviar',
    lastFairEdStart: 'Início',
    lastFairEdEnd: 'Fim',
    agree: '“LI E CONCORDO”',
    onlineRegistrationTerm: 'Inscrição on-line',
    onlineRegistration: 'PARA ACESSAR A INSCRIÇÃO ONLINE, CLIQUE EM ',
    afterParticipation: 'APÓS LER TODOS OS CRITÉRIOS DE PARTICIPAÇÃO ABAIXO.',
    calendarLocale: 'pt-BR',
    registrationFailedIcAlreadyExists: 'Falha no cadastro, usuário já existe! Verifique o Identification Code inserido',
    registrationFailedEmailAlreadyExists: 'Falha no cadastro, usuário já existe! Verifique o E-mail inserido',
    registrationFailedCpfAlreadyExists: 'Falha no cadastro, usuário já existe! Verifique o CPF/CNPJ inserido',
    exit: 'Sair',
    welcomeHomeHeader1: 'Para participar da feira',
    welcomeHomeHeader2: ', selecione entre as opções abaixo e realize seu pré-cadastro, onde validaremos os seus dados para continuar no processo desejado.',
    welcomeHomePaymentInfo1: 'A inscrição é gratuita e você pagará apenas a taxa de participação se for aceito(a)!',
    welcomeHomePaymentInfo2: 'Também contamos com isenções, caso necessite.',
    fairAffiliation: 'Afiliação de feira',
    welcomeHomeFairAffiliationText: 'Se você representa alguma feira escolar, estadual, nacional ou internacional e é entusiasta da ciência no país e dispõe de bons projetos, informe-se e afilie a sua feira à Mostratec!',
    subscribeNow: 'Inscreva-se já!',
    highEnrolled: 'a) estar matriculado em curso de Ensino Médio ou de Educação Profissional',
    levelHigh: 'Técnica de Nível Médio;',
    colaborate: 'c) Os finalistas comprometem-se a colaborar com os órgãos de imprensa e',
    studyAreas: 'c) ter, como objeto de estudo, uma das áreas de conhecimento definidas',
    byMostratec: 'pela Mostratec;',
    investigation: 'd) enfocar uma das seguintes características: investigação e/ou inovação;',
    twelveMonthProject: 'e) apresentar o trabalho contínuo de 12 (doze) meses, iniciado no ano',
    yearsOld: 'b) ter idade mínima de 14 anos;',
    yourProjects: 'seus projetos.',
    loseSpot: 'acima, caso contrário, perdem a vaga.',
    establishedCriteria: '– os projetos devem, obrigatoriamente, atender aos critérios estabelecidos',
    middleLevel: 'ensino médio ou de educação profissional de nível técnico;',
    studentAuthory: '– salientamos que o projeto deve ser de autoria do(s) estudante(s) do',
    otherFairs: 'em outras feiras, eventos e junto a órgãos de fomento à pesquisa.',
    participatingWorks: 'das quantidades de trabalhos participantes para fins de credenciamentos',
    awarding: 'nomes (e respectivas instituições de ensino) para fins de premiação e uso',
    namePermission: 'd) Os finalistas dão permissão à Comissão Central para divulgação de',
    projectDeadline: 'b) inscrever o projeto nos prazos estabelecidos pela Mostratec;',
    precedingFair: 'precedente à feira.',
    press: 'b) Os órgãos de imprensa (escrita, falada e visual), estão autorizados a',
    informationPublicizing: 'afins para dar informações com a finalidade de divulgação do evento e de',
    publicizing: 'utilizarem dados ou imagens com a finalidade de divulgação do evento.',
    maxThreeStudents: 'f) ser desenvolvido por, no máximo, 3 alunos.',
    onlyOneProject: 'd) participar apenas com um projeto científico ou tecnológico.',
    centralComission: 'a) Os finalistas dão permissão à Comissão Central para usar de informações',
    photos: '(fotos, vídeos, folders, entre outros) para divulgação da MOSTRATEC.',
    schoolBasic: 'a) estar matriculado em escola básica curricular;',
    responsible: 'c) estar acompanhado de um responsável, se menor de idade;',
    submitProject: 'd) inscrever o projeto nos prazos estabelecidos pela Mostratec;',
    techProject: 'e) participar apenas com um projeto científico ou tecnológico.',
    hasAdvisors: 'a) ter um orientador (maior de 21 anos);',
    rulesAccordance: 'b) ser elaborado de acordo com as regras de pesquisa científica da Mostratec;',
    projectRegistration: 'Inscrição de projeto',
    affiliatedFairSingle: 'Feira Afiliada',
    publicity: 'Concessão de publicidade:',
    comments: 'Observações:',
    participationCriteria: 'APÓS LER TODOS OS CRITÉRIOS DE PARTICIPAÇÃO ABAIXO.',
    mostratecCriteria: 'Critérios de participação na MOSTRATEC',
    subscription: 'PARA ACESSAR A INSCRIÇÃO ONLINE, CLIQUE EM',
    onlineRegistration: 'Inscrição on-line',
    readDocument: 'Declaro que li, estou ciente e concordo com os termos expostos acima. (resposta obrigatória).',
    EJA: 'Para o aluno de nível fundamental, EJA ou educação infantil:',
    forProject: 'Para o projeto:',
    forStudent: 'Para o aluno:',
    agreed: '“LI E CONCORDO”',
    term: 'Termo de Consentimento para Tratamento de Dados Pessoais',
    welcomeHomeProjectRegistrationText1: 'Para projetos credenciados por ',
    welcomeHomeProjectRegistrationText2: 'ou para submissão no',
    welcomeHomeProjectRegistrationText3: ': Logo chegará o momento de realizar a sua inscrição, aguarde!',
    moreInformation: 'Para maiores informações, visite',
    illustrationsBy: 'Ilustrações:',
    preRegistration: 'Pré-Cadastro',
    inputName: 'Insira o seu nome',
    inputFairName: 'Insira o nome da feira',
    inputCnpjCpf: 'Insira o CNPJ da instituição ou CPF',
    inputEmailToConfirmRegistration: 'Insira o email para confirmação de pré-cadastro',
    inputPasswordToLogin: 'Crie uma senha para logar no sistema',
    passwordConfirm: 'Confirme a senha',
    warningAllFieldsAreObligatory: 'Todos os campos devem ser preenchidos.',
    warningAllRequiredFieldsAreObligatory: 'Todos os campos obrigatórios devem ser preenchidos.',
    warningEmailAlreadyUsed: 'Falha no cadastro, E-mail já está em uso',
    warningCnjpCpfIdAlreadyUsed: 'Falha no cadastro, cpf/cnpj/identification number já está em uso',
    warningTryLater: 'Erro, tente novamente mais tarde',
    welcomeProjectHeader1: 'Antes de continuarmos, nos diga...',
    welcomeProjectHeader2: 'Por qual dos seguintes tipos de inscrição de projeto você pretende prosseguir?',
    //welcomeProjectRegistrationBySelectionProcess: 'Para aqueles que já possuem um projeto científico e tem interesse em passar pelo processo de seleção para participar da feira de modo independente.',
    welcomeProjectRegistrationBySelectionProcess: 'Para projetos que têm interesse em passar pelo processo de seleção para participar da Mostratec de modo independente.',
    byAffiliatedFair: 'Via Feira Afiliada',
    //welcomeProjectRegistrationByAffiliatedFair: 'Para aqueles que desejam inscrever-se e participar da feira por meio das Feiras Afiliadas cadastradas. (Ensino médio / técnico)',
    welcomeProjectRegistrationByAffiliatedFair: 'Para projetos que receberam credencial de uma feira afiliada à Mostratec.',
    teachingLevels: 'Níveis de ensino contemplados:',
    highSchoolTechnical: 'Ens. médio / técnico',
    elementarySchool: 'Ens. fundamental',
    childEducation: 'Ed. infantil',
    responsibleStudentName: 'Nome do aluno responsável',
    inputTheResponsibleStudentName: 'Insira o nome do aluno responsável pelo projeto',
    projectRegistrationWarningCnjpCpfIdAlreadyUsed: 'Falha no cadastro, usuário já existe! Verifique o E-mail e CPF/CNPJ ou Identification Number (se estrangeiro)',
    selectFairName: 'Selecione o nome da feira',
    inputYourCPF: 'Insira o seu CPF',
    vacanciesRequest: 'Solicitação de vagas/número de projetos solicitados', // 'Requisição De Vagas X',
    vacanciesRequestResponse: 'Vagas concedidas / Aceite de projetos', // 'Requisição De Vagas X',
    panelFairVacanciesWaitWillBeAnalyzed: 'Aguarde. Sua requisição de vagas foi recebida por nosso sistema e será analisada em seguida.',
    panelFairVacanciesWaitUnderAnalysis: 'Aguarde. Sua requisição de vagas está em análise e logo iremos retornar com a resposta via email.',
    panelFairVacanciesUnsignedSubscription: 'Sua inscrição ainda não foi finalizada. Dê andamento ao seu cadastro',
    clickingHere: 'clicando aqui',
    affiliationRequest: 'Solicitar afiliação',
    thereIsNoData: 'Não há dados',
    fairSelect: 'Selecione uma feira',
    panelFairVacanciesDenied: 'Agradecemos sua requisição, mas não foi possível conceder vagas para afiliação nesta edição.',
    reason: 'Motivo',
    fairPeriodicity: 'Periocidade da feira',
    periodOfLastFair: 'Período da realização da última feira',
    periodForNextFair: 'Período da realização da próxima feira',
    to: 'até',
    nextFairDateIsConfirmated: 'A data de realização da próxima feira é confirmada?',
    yes: 'Sim',
    no: 'Não',
    clickingHere: 'Clicando aqui',
    nextFairFormat: 'Formato da próxima feira',
    editionsFairNumber: 'Número de edições da feira',
    doesTheFairHaveCrc: 'A feira tem um comitê de revisão científica (CRC)?',
    educationLevelsInTheFair: 'Níveis de ensino que participam da feira:',
    childEducationCompleted: 'Educação Infantil',
    childEducationCompleted2024: 'Educação Infantil - pré-escola (estudantes de 4 a 6 anos)',
    elementarySchoolCompleted: 'Ensino fundamental (1º ao 9º ano)',
    highSchoolOrTechnicalCompleted: 'Ensino médio/técnico',
    childEducationategory0: 'Educação Infantil - pré-escola',
    elementarySchoolCategory1: 'Ensino Fundamental - Categoria 1 (1º ao 3º ano)',
    elementarySchoolCategory2: 'Ensino Fundamental - Categoria 2 (4º ao 6º ano)',
    elementarySchoolCategory3: 'Ensino Fundamental - Categoria 3 (7º ao 9º ano)',
    // elementarySchoolCategory4: 'NO',
    elementarySchoolCategoryEja: 'Ensino Fundamental - Categoria 4 Educação de Jovens e Adultos EJA (todos os anos)',
    highSchoolAndOrTechnical: 'Ensino Médio e/ou Educação Técnica de Nível Médio',
    universityEducation: 'Ensino superior',
    users: 'Usuários',
    profileAccess: 'Perfís de acesso',
    userSettings: 'Configuração de usuários',
    status: 'Status',
    school: 'Escola',
    confirmationEmailSent: 'O e-mail de confirmação foi enviado com sucesso! Para finalizar o seu cadastro, acesse a caixa do e-mail informado no formulário e confirme os dados informados.',
    affiliatedFairStudent: 'Aluno Feira Afiliada',
    lastEdition: 'Última edição',
    aboutFair: 'Sobre a feira',
    aboutSchool: 'Sobre a escola',
    requestVacancies: 'Aceite dos projetos',
    responsibilityTerm: 'Termo de responsabilidade',
    projectsNumber: 'Número de projetos',
    teachersNumber: 'Número de professores',
    schoolsNumber: 'Número de escolas',
    male: 'Masculinos',
    female: 'Femininos',
    totalStudents: 'Total de alunos',
    projectsWithOneStudent: 'Número de projetos com 1 aluno',
    projectsWithTwoOrThreeStudent: 'Número de projetos com 2 ou 3 alunos',
    generalInformation: 'Informações gerais',
    schoolNumberThatParticipatedOnFair: 'Número de escolas que participaram da sua feira',
    otherAffiliatedFairParticipe: 'Participam outras feiras afiliadas',
    publicSchoolNumber: 'Número de escolas públicas',
    privateSchoolNumber: 'Número de escolas privadas',
    fairAffiliatesNumber: 'Número de afiliados da feira',
    responsibilityTermParagraph1: 'Estou ciente dos dados informados no formulário de afiliação, sendo que os mesmos estão de acordo com a feira realizada.',
    //responsibilityTermParagraph1: 'Estou ciente e confirmo a veracidade dos dados informados no formulário de inscrição.',
    responsibilityTermParagraph2: 'Assumo o compromisso de credenciar o(s) projeto(s) conforme os critérios de participação e o regulamento da Mostratec e/ou Mostratec Júnior.',
    responsibilityTermParagraph3: 'Estou ciente de que, caso a feira selecione um projeto que esteja em desacordo com o estabelecido acima, o projeto não será aceito e a feira afiliada poderá perder o direito de renovar a afiliação no ano seguinte.',
    resonsablePersonForFillingIn: 'Nome do responsável pelo preenchimento',
    positionFunctionAtFair: 'Cargo / Função na feira',
    vacanciesListed: 'Vagas inscritas',
    parentageOfResponsible: 'Parentesco do(a) responsável',
    insertParentage: 'Insira o parentesco do responsável',
    adultsName: 'Insira o nome do adulto responsável',
    noProject: 'Você ainda não possui nenhum projeto inscrito na Mostratec. Atente-se aos prazos e taxa de inscrição e clique em Solicitar inscrição para começar!',
    vacanciesRequested: 'Vagas solicitadas',
    vacanciesGranted: 'Vagas concedidas',
    vacanciesExempt: 'Vagas isentas',
    researchArea: 'Áreas de pesquisa',
    disabled: 'Desativada',
    deactivatedRequest: 'Sua solicitação foi desativada.',
    requestDate: 'Data da solicitação',
    lastUpdate: 'Última atualização',
    registrationInProgress: 'Inscrição em andamento',
    considerAllFieldObligatory: 'Considere todos os campos obrigatórios, a não ser que conste o texto (opcional) no título.',
    schoolEmail: 'E-mail da instituição/escola',
    schoolEmailPlaceHolder: 'Insira o e-mail da instituição/escola',
    aboutYourFair: 'Sobre a última edição da feira afiliada',
    generalInfos: 'Infos gerais',
    vacanciesRequestsInPlural: 'Requisições de Vagas',
    checkTheData: 'Verifique os dados informados!',
    inputInstitutionCepPlaceholder: 'Insira o CEP da sua instituição',
    inputCepPlaceholder: 'Insira o CEP',
    addressExample: 'Rua, Avenida...',
    checkTheCep: 'Informe um CEP válido',
    contactOneInformation: 'As informações do contato 1, serão utilizadas para notificações referente a feira Mostratec',
    inputContactEmail: 'Insira o e-mail de contato',
    main: '(Principal)',
    addContact: 'Adicionar contato',
    emailsMustBeDifferent: 'E-mails inseridos devem ser distintos!',
    schoolFairType: 'Escolar (participam projetos somente de uma escola)',
    multiSchoolFairType: 'Multiescolar (participam projetos de diferentes escolas, podendo ser de uma mesma rede ou de redes diferentes, inclusive de cidades diferentes);',
    townFairType: 'Municipal (participam exclusivamente projetos da rede municipal de educação);',
    townFairType2: 'Municipal - outras redes (participam mais de uma rede de ensino: municipal e/ou estadual e/ou privada)',
    regionalFairType: 'Regional',
    stateFairType: 'Estadual',
    nationalFairType: 'Nacional',
    internationalFairType: 'Internacional',
    whatsIsTheNextFairDate: 'A data de realização da próxima feira é?',
    confirmated: 'Confirmada',
    forecastBecause: 'Previsão, pois a data ainda não está confirmada',
    editionsFairNumberPlaceholder: 'Insira o número de edições',
    checkTheEducationalLevels: 'Assinale os níveis de ensino que participam de sua feira:',
    specifyTheFollowingData: 'Informe os dados a seguir de acordo com o(s) nível(is) de ensino participante(s) em sua feira. Caso sua feira não contemple algum dos níveis de ensino mencionados abaixo, não é necessário o preenchimento.',
    vacanciesNumberRequested: 'O número de vagas solicitado aqui não será necessariamente o número de projetos que a feira poderá credenciar. Para saber quantos projetos a feira poderá indicar para participar da MOSTRATEC, deve-se aguardar o retorno da solicitação.', // 'O número de vagas solicitado não será necessariamente o número de vagas concedido.',
    ifYourFairDoesNotIntendCoverAllTypes: 'Se a sua feira não pretende contemplar todas as modalidades de ensino, não é necessário preencher todos os campos.',
    howManyProjects: 'Quantos projetos?',
    howManyProjectsAbout: 'Quantos projetos:',
    projectsNumberPlaceholder: 'Insira o número de projetos',
    schoolsNumberPlaceholder: 'Insira o número de escolas',
    teachersNumberPlaceholder: 'Insira o número de professores',
    studentsNumberPlaceholder: 'Insira o número de alunos',
    projectsWithOneStudentPlaceholder: 'Insira o número de projetos com 1 aluno',
    projectsWithTwoOrThreeStudentPaceholder: 'Insira o número de projetos com 2 ou 3 alunos',
    oneLevelIsNecessary: 'Necessário informar dados para pelo menos um nível de ensino.',
    allFieldsSelectedMustBeCompleted: 'Todos os campos do nível selecionado devem ser preenchidos.',
    thereAreExistingSolicitations: 'Já existem solicitações abertas!',
    totalOfSchoolsInYourFair: 'Nº Total de escolas que participaram da sua feira',
    DidOthersAffiliatedFairsParticipate: 'Na sua feira participaram outras feiras afiliadas?',
    didOthersAffiliatedFairsParticipateAbout: 'Participaram outras feiras afiliadas',
    affiliatedFairsNumber: 'Número de feiras afiliadas',
    affiliatedFairsNumberPlaceholder: 'Insira o número de feiras afiliadas',
    vacanciesNumber: 'Número de vagas/Número de projetos',
    vacanciesNumberPlaceholder: 'Insira a quantia pretendida',
    tickTheResearchArea: 'Assinale área de pesquisa (Opcional)',
    biology: 'Biologia',
    biologyAndChemistry: 'Biologia e Química',
    sciences: 'Ciências (ambientais, animais e das plantas)',
    computerScience: 'Ciências da Computação',
    planetaryAndEarthSciences: 'Ciências Planetárias e terrestres, Matemática e Física',
    socialSciencesBehaviorArt: 'Ciências Sociais, Comportamento e Arte',
    engineering: 'Engenharia (Elétrica, Eletrônica, Mecânica)',
    engineeringAndMaterials: 'Engenharia e Materiais',
    environmentalManagement: 'Gerenciamento do Meio Ambiente',
    medicineAndHealth: 'Medicina e Saúde',
    otherResearchArea: 'Outro? Digite a área de pesquisa',
    categoryDesired: 'Informe a(s) categoria(s) na(s) qual(is) sua feira gostaria de participar e a quantidade de projetos pretendidos', // 'Informe a(s) categorias na(s) qual(is) sua feira vai participar e quantidade de vagas pretendidas',
    makeSureToFillIn: 'Certifique-se de preencher os campos.',
    readTheFollowingTerm: 'Leia o termo de responsabilidade a seguir',
    readAndAgree: 'Li e concordo',
    resonsablePersonForFillingInPlaceholder: 'Insira o nome do responsável',
    positionFunctionAtFairPlaceholder: 'Insira o cargo/função junto à feira',
    sendForm: 'Enviar formulário',
    makeSureToReadAndAgree: 'Certifique-se de ler e concordar antes de prosseguir.',
    requestSentSuccessfully: 'Solicitação enviada com sucesso! Você será redirecionado ao Painel da feira para acompanhar sua solicitação!',
    errorSendingForm: 'Erro no envio do formulário',
    emailsAlreadyInUseChangeThemOnForm3: 'E-mails de contato já em uso em outra solicitação, altere-os no formulário 3 - Contatos',
    requestSentSuccessfullyTitle: 'Solicitação enviada com sucesso',
    VacanciesGrantedMustBeProvided: 'Todos os campos de Vagas Concedidas devem ser fornecidos',
    VacanciesGrantedValuesNotValid: 'Valores de vagas concedidas não são válidos',
    requestDisabled: 'Solicitação desativada',
    biochemistryAndChemistry: 'Bioquímica e Química',
    other: 'Outro',
    'inscricao em andamento': 'Inscrição em andamento',
    'pendente de analise': 'Pendente de análise',
    'em analise': 'Em análise',
    'aguardando resposta': 'Aguardando resposta',
    'resposta enviada': 'Resposta enviada',
    whatIsYourEducationalLevel: 'Qual é o seu nível de ensino?',
    confirmYourEducationalLevel: 'Confirme o seu nível de ensino para prosseguir com o formulário de inscrição de seu projeto na edição ativa.',
    levelsOfEducational: 'Nível de ensino',
    confirmAndContinue: 'Confirmar e continuar',
    levelsOfEducationalDescription: 'As inscrições do tipo Processo de Seleção são voltadas unicamente para o ensino médio e técnico. Confirme se pertence a esse nível de ensino para prosseguir com sua inscrição na Mostratec 2021.',
    activeEditionForSubscription: 'Edição ativa para inscrição',
    fillOutTheFormBelow: 'Preencha o formulário a seguir de acordo com seu tipo de cadastro para realizar a inscrição de seu projeto.',
    toStartButton: 'COMEÇAR',
    automaticSavingModalText1: 'O sistema conta com salvamento automático, cada vez que você apertar o botão de "próximo" no formulário, a página anterior será automaticamente salva.',
    automaticSavingModalText2: 'Garantimos à você, que suas informações estão guardadas em segurança. Para retomar o preenchimento da inscrição, basta clicar em "sim, continuar".',
    autoSave: 'Salvamento automático',
    informWhichSchoolYouAreEnrolled: 'Informe em qual escola / instituição está / esteve matriculado durante o desenvolvimento do projeto',
    nameOfSchoolOrInstitution: 'Nome da escola / instituição',
    yourSchoolIsNotInTheSystem: 'Sua escola / instituição não consta no sistema?',
    newInstitution: 'Nova Instituição',
    institutionNameSingle: 'Nome da instituição',
    institutionNameSinglePlaceholder: 'Insira o nome da instituição',
    educationalInstitutionCategoryCompleted: 'Categoria de instituição de ensino',
    institutionPersonInCharge: 'Nome do responsável da instituição',
    institutionPersonInChargePlaceholder: 'Insira do responsável da instituição',
    IfThereIsNoCNPJLinkedToTheSchool: 'Caso não haja nenhum CNPJ vinculado a escola, então insira no campo acima o CPF do responsável da instituição.',
    aboutStudents: 'Sobre os estudantes',
    aboutAdvisors: 'Sobre os orientadores',
    othersInvolved: 'Outros envolvidos',
    researchPlan: 'Plano de pesquisa',
    summaryForTheResearcher: 'Resumo para o pesquisador',
    fileUpload: 'Upload de arquivos',
    buttonContinue: 'Continuar',
    tc_titulo: 'Termo de Consentimento para Tratamento de Dados Pessoais em atendimento à Lei Brasileira n°13.709, de 14 de agosto de 2018, Lei Geral de Proteção de Dados Pessoais LGPD.',
    tc_aviso: 'Leia com atenção todos os itens abaixo antes de prosseguir.',
    tc_p1: 'Este formulário está em conformidade com a Política de Dados Pessoais da Fundação Escola Técnica Liberato Salzano Vieira da Cunha e a Lei Geral de Proteção de Dados Pessoais (LGPD).',
    tc_p2: 'O controlador (Fundação Liberato) é responsável por qualquer dado que será tratado.',
    tc_p3: 'Declaro estar ciente sobre meus DIREITOS em relação a coleta e tratamento de dados pessoais conforme Lei Geral de Proteção de Dados Pessoais (LGPD) nº 13.709, de 14 de agosto de 2018 e expresso o MEU CONSENTIMENTO para coleta e tratamento de DADOS neste formulário.',
    tc_p4: 'Declaro estar ciente que terei ACESSO aos dados pessoais coletados a qualquer tempo e que poderei solicitar uma cópia desses dados; que poderei RETIFICAR os meus dados pessoais coletados a qualquer momento de forma irrestrita; que poderei solicitar a EXCLUSÃO dos dados aqui coletados a qualquer tempo; que poderei CONTESTAR o processamento dos dados pessoais aqui coletados, por motivos relacionados a situações diversas e/ou particulares; que poderei RETIRAR O MEU CONSENTIMENTO, a qualquer momento; que poderei SOLICITAR A PORTABILIDADE DE DADOS podendo ser me devolvidos.',
    tc_p5: 'Concessão de publicidade:',
    tc_p6: 'a) Dou permissão à Comissão Central da MOSTRATEC para utilizar as informações fornecidas (nomes, títulos dos projetos, instituição de ensino, feiras afiliadas, resumos), fotos, vídeos, folders, entre outros, para divulgação da MOSTRATEC;',
    tc_p7: 'b) Os órgãos de imprensa (escrita, falada e visual), estão autorizados a utilizarem dados ou imagens com a finalidade de divulgação do evento;',
    tc_p8: 'c) Os finalistas comprometem-se a colaborar com os órgãos de imprensa e afins para dar informações com a finalidade de divulgação do evento e de seus projetos;',
    tc_p9: 'd) Os finalistas dão permissão à Comissão Central da MOSTRATEC para compartilhamento de dados, como nomes, contatos, instituições de ensino, títulos e resumos dos projetos para fins de premiação, credenciamentos em outras feiras, eventos, junto a órgãos de fomento à pesquisa e instituições parceiras e correalizadoras da MOSTRATEC;',
    tc_p10: 'e) Os responsáveis pelas feiras afiliadas dão permissão à Comissão Central da MOSTRATEC para divulgação dos nomes das feiras e suas instituições organizadoras, seus responsáveis e respectivos contatos, para fins de fomento à pesquisa junto às instituições parceiras e correalizadoras da MOSTRATEC.',
    linkLei13709: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm',
    DeclareAsRead: 'Aviso: Por gentileza, declare como lido o Termo de Consentimento para Tratamento de Dados Pessoais LGPD antes de prosseguir.',
    thereAreNoResearchAvaliable: 'Não há áreas de pesquisa disponíveis para esse nível de ensino',
    importantTitle: 'Importante!',
    revalidationByAdminText: 'Após sua confirmação, as informações da analise anterior serão perdidas sendo necessário uma nova reavaliação. Deseja continuar?',
    profile: 'Perfil',
    edition: 'Edição',
    notAccept: 'Não aceito',
    termLGPD: 'Termo LGPD',
    noResults: 'Sem resultados',
    neighborhoodPlaceholder: 'Insira seu bairro',
    statePlaceholder: 'Insira seu estado',
    cityPlaceholder: 'Insira a cidade',
    projectName: 'Título do projeto',
    enterTheProjectName: 'Informe o nome do projeto',
    checkYourProjectResearchArea: 'Assinale a área de pesquisa de seu projeto',
    art: 'Artes',
    bio: 'Biologia',
    eduPedago: 'Educação e pedagogia',
    eletronicInfoRobo: 'Elêtronica, informática e robótica',
    enge: 'Engenharia',
    filoso: 'Filosofia',
    fis: 'Física',
    geo: 'Geografia',
    his: 'História',
    incAcessTec: 'Inclusão, acessibilidade e tecnologia',
    qui: 'Química',
    lingPortLingStrangLit: 'Língua portuguesa, língua estrangeira e literatura',
    mat: 'Matemática',
    meioAmb: 'Meio ambiente',
    saud: 'Saúde',
    socio: 'Sociologia',
    enterTheOtherSubarea: 'Insira a outra subárea',
    enterTheSummary: 'Insira o resumo',
    enterTheThema: 'Insira o tema',
    summary: 'Resumo',
    theme: 'Tema',
    registrationClosed: 'Inscrições encerradas',
    caseYouMissedTheOpportunity: 'Caso tenha perdido a oportunidade, aguardamos sua inscrição na próxima edição da Mostratec!',
    isTimeToRegister: 'Chegou o momento de realizar sua inscrição!',
    authors: 'Autores',
    selectSituation: 'Selecione a situação',
    approved: 'Aprovado',
    reproved: 'Reprovado',
    insertRejectReason: 'Insira o motivo da recusa',
    saveAndSend: 'Salvar e enviar',
    informAboutTheAdvisors: 'Informe abaixo os dados do(a) orientador(a) e coorientador(a), caso exista.',
    advisor: 'Orientador(a)',
    coAdvisor: 'Coorientador(a)',
    graduation: 'Formação',
    actingDiscipline: 'Disciplina de atuação',
    enterTheGraduation: 'Insira a formação',
    enterTheActingDiscipline: 'Insira a disciplina de atuação',
    haveYouParticipatedAnyOtherMostratec: 'Já participou de alguma outra edição da Mostratec?',
    disableCoadvisor: 'Desabilitar coorientador(a)?',
    disableCoadvisorModalP1: 'Você tem certeza que deseja desabilitar o cadastro de coorientador deste projeto? Ao inativar, você irá inscrever seu projeto',
    whitoutCoadvisor: 'sem um(a) coorientador(a).',
    disableCoadvisorModalP2: 'Para habilitá-lo novamente, basta reativá-lo através do',
    caseThereAreOthersInvolvedDescription: 'Os campos abaixo devem ser preenchidos caso haja outras pessoas envolvidas no projeto que venham a participar da MOSTRATEC, mas não desenvolveram o projeto. Exemplo: chefe de delegação, pais, aluno observador, etc.',
    caseThereAreOthersInvolved: 'Existem outras pessoas envolvidas neste projeto?',
    addNewInvolved: 'Adicionar novo envolvido',
    person: 'Pessoa',
    designation: 'Designação',
    enterDesignation: 'Insira a designação',
    designationExample: 'Exemplo: Diretor, Chefe de delegação, pais ou responsáveis, etc.',
    enterTheSpecializedService: 'Insira o/os atendimento especializado',
    disablePeopleInvolved: 'Desabilitar pessoas envolvidas?',
    disablePeopleInvolvedParagraph: 'Você tem certeza que deseja desabilitar o cadastro de outras pessoas envolvidas neste projeto? Ao inativar, você perderá o conteúdo inserido em seus campos e essa ação não poderá ser desfeita.',
    projectAbstractIntro: 'O resumo será encaminhado para os avaliadores e publicado no site da Mostratec e deve apresentar:',
    projectAbstractIntroA: 'a) objetivo do projeto ou da experiência executada;',
    projectAbstractIntroB: 'b) os procedimentos usados;',
    projectAbstractIntroC: 'c) as observações / os dados / os resultados (sucintamente);',
    projectAbstractIntroD: 'd) as conclusões / aplicações;',
    projectAbstractIntroE: 'e) as palavras-chave.',
    enterTheProjectAbstract: 'Insira o resumo do projeto',
    youCanEditTheAbstract: 'Você poderá editar o resumo de seu projeto após o envio da sua inscrição até que o prazo de inscrição se encerre.',
    reviewProjectData: 'Revise as principais informações de seu projeto de pesquisa. Se algum dado abaixo estiver incorreto, sinta-se à vontade para editá-lo entre as etapas passadas do processo de inscrição. Lembre-se que, assim que enviado o pedido de inscrição, você não poderá alterar os dados.',
    students: 'Estudantes',
    researchProjectStartDate: 'Data de início do projeto de pesquisa',
    plannedEndDate: 'Data planejada para o fim',
    willYourProjectBeContinued: 'Seu projeto será continuado?',
    plannedDateForStartingExperimentation: 'Data em que a etapa de continuidade da pesquisa iniciou a experimentação/coleta de dados',
    willYouConductInLaboratory: 'Você conduziu a etapa de continuidade da pesquisa em laboratório, instituto ou indústria regulamentada?',
    willTheContinuityIncludeNewStudents: 'A etapa de continuidade do projeto contou com novos estudantes?',
    willTheContinuityIncludeNewResearch: 'A etapa de continuidade do projeto teve outra área ou subárea de pesquisa?',
    researchInstituitionIndustry: 'Instituição de pesquisa ou indústria regulamentada',
    telephone: 'Telefone',
    locationsWhereTheResearchWillBeConducted: 'Locais em que a pesquisa será conduzida',
    field: 'Campo',
    atHome: 'Em casa',
    itemsThatApplyToYourResearchTrials: 'Itens que se aplicam às experimentações de sua pesquisa',
    humanBeings: 'Seres Humanos',
    vertebrateAnimals: 'Animais vertebrados',
    potentiallyHazardousBiologicalAgents: 'Agentes Biológicos Potencialmente Perigosos',
    hazardousChemicalSubstances: 'Substâncias Químicas, Atividades ou Equipamentos Perigosos',
    substancesControlledByLaw: 'Substâncias Controladas por Lei',
    addNewStudent: 'Adicionar novo estudante',
    disableStudentInvolved: 'Desabilitar estudante envolvido?',
    disableStudentInvolvedInfo: 'Você tem certeza que deseja desabilitar o cadastro de outros estudantes envolvidos neste projeto? Ao inativar, você perderá o conteúdo inserido em seus campos e essa ação não poderá ser desfeita.',
    filesMustBeSent: 'Os arquivos devem ser enviados até o término das inscrições',
    requiredFiles: 'Arquivos obrigatórios',
    downloadForms: 'Baixar formulários',
    fileFormatMustBePDF: 'O formato do arquivo deve ser em PDF',
    workPresentation: 'Apresentação do trabalho',
    fileFormatMustBeMP4: 'O formato do arquivo deve ser em MP4',
    dragAndDropTheFile: 'Arraste e solte o arquivo ou',
    select: 'selecione',
    invalidFile: 'Arquivo inválido. Tente novamente com o formato correto',
    specificFiles: 'Arquivos específicos',
    filesRequestBelowInfo: 'Os arquivos abaixo solicitados, são correspondentes a sua pesquisa e foram definidos de acordo suas respostas na página',
    continuityProject: 'Projeto de continuidade',
    riskAssessment: 'Avaliação de riscos',
    qualifiedScientist: 'Cientista qualificado',
    projectContinuityFileFormat: 'Para continuidade de projetos. O formato do arquivo deve ser em PDF',
    vertebrateAnimalsFileFormat: 'Para pesquisas que envolvem animais vertebrados adicione o Formulário 5. O formato do arquivo deve ser em PDF',
    humanBeingsFileFormat: 'Para pesquisas que envolvem seres humanos adicione o Formulário 4. O formato do arquivo deve ser em PDF',
    dangerousSubstancesFileFormat: 'Para pesquisa com agentes biológicos potencialmente perigosos adicione o Formulário 6A. O formato do arquivo deve ser em PDF',
    animalTissueFileFormat: 'Para pesquisa com tecidos de seres humanos ou de animais vertebrados adicione o Formulário 6B. O formato do arquivo deve ser em PDF',
    instituitionFileFormat: 'Para pesquisa em instituição de pesquisa ou indústria regulamentada adicione o Formulário 1C. O formato do arquivo deve ser em PDF',
    submitRegistration: 'Enviar inscrição',
    ifYouChangeTheEducationalLevel: 'Aviso: se alterar o nível de ensino, você perdera os dados salvos anteriormente no formulário.',
    saveAndAdvance: 'Salvar e avançar',
    selectEtc: 'Selecione...',
    selectLevel: 'Selecione um nível',
    confirmWhichOfOurAffiliatedFairsIsYou: 'Confirme por qual de nossas feiras afiliadas você solicitará a inscrição de seu projeto. Se a sua feira não constar em nosso sistema, entre em contato com a mesma.',
    fair: 'Feira',
    fairNamePlaceholder: 'Nome feira',
    thereAreNoFairsToYourLevel: 'Não há feiras para o seu nível de ensino',
    coexistenceContract: 'Contrato de convivência',
    //coexistenceContractInfo: 'Estou ciente dos dados informados no formulário de inscrição, sendo que os mesmos estão de acordo com a feira realizada. Assumo o compromisso de enviar o arquivo contrato de convivência para Mostratec Júnior, seja virtual ou presencial.',
    coexistenceContractInfo: 'Estou ciente e confirmo a veracidade dos dados informados no formulário de inscrição.',
    howWillTheContractBeDelivered: 'Como será a entrega do contrato?',
    resultsAndConclusions: 'Resultados e conclusões',
    resultsAndConclusionsPlaceholder: 'Insira os resultados e conclusões da pesquisa',
    resultsAndConclusionsTextInfo: 'O seu propósito se trata de demonstrar aquilo que foi descoberto ou compreendido pelos autores a partir da conclusão da pesquisa.',
    researchProblem: 'Problema de pesquisa',
    projectCategory: 'Categoria do projeto',
    neighborhood: 'Bairro',
    grade: 'Série',
    childreanTShirt: 'Infantil',
    elementarySchoolCategory1Single: '1º ao 3º ano',
    elementarySchoolCategory2Single: '4º ao 6º ano',
    elementarySchoolCategory3Single: '7º ao 9º ano',
    maleTShirt: 'Masculina',
    sizeSix: '4-5 anos - Tamanho 6',
    sizeEight: '5-7 anos - Tamanho 8',
    sizeTen: '7-9 anos - Tamanho 10',
    sizeTwelve: '11-12 anos - Tamanho 12',
    sizeFourteen: '13-14 anos - Tamanho 14',
    //-------------------------------------------------------
    tsShirt01: 'Infantil - Tamanho 8',
    tsShirt02: 'Infantil - Tamanho 10',
    tsShirt03: 'Infantil - Tamanho 14',
    tsShirt04: 'Adulto - P',
    tsShirt05: 'Adulto - M',
    tsShirt06: 'Adulto - G',
    tsShirt07: 'Adulto - GG',
    tsShirt08: 'Adulto - EXG',
    //-------------------------------------------------------
    class: 'Turma',
    quartet: 'Quarteto',
    nursery: 'Berçário',
    maternal: 'Maternal',
    kindergarten: 'Pré',
    lessThan1Year: 'Menos de 1 ano',
    OneTwoYears: '1 a 2 anos',
    ThreeFourYears: '3 a 4 anos',
    FiveSixYears: '5 a 6 anos',
    advisorIsMandatory: 'Orientador é obrigatório',
    requiredJustify: 'A justificativa é obrigatória',
    selectionSituation: 'Por favor, selecione a situação do projeto',
    landlineOrCellphone: 'Telefone (fixo ou celular)',
    studentResponsibleForPreRegistration: 'Estudante responsável pelo pré-cadastro e pela inscrição do projeto.',
    peopleInvolved: 'Pessoas envolvidas',
    advisors: 'Orientadores',
    willTheProjectContinuityHaveAnotherResearchArea: 'A continuidade do projeto terá outra área ou subárea de pesquisa?',
    newResearchSubareas: 'Novas subáreas de pesquisa',
    studentsQuantityByProject: 'Quantidade de estudantes por projeto',
    single: 'Solo',
    double: 'Dupla',
    canStudentsPresentLive: 'O(s) estudante(s) do projeto pode(m) participar de uma apresentação ao vivo?',
    student: 'Estudante',
    responsibleStudentForProjectPreRegistrationAndSubscribe: 'Estudante responsável pelo pré-cadastro e pela inscrição do projeto.',
    newStudent: 'Novo estudante',
    cpf: 'CPF',
    cpfStudent: 'CPF do estudante',
    genderedAdvisor: 'Orientador(a)',
    genderedCoAdvisor: 'Coorientador(a)',
    assignmentPresentation: 'Apresentação do trabalho',
    humanBeingsNoun: 'Seres humanos',
    risksEvaluation: 'Avaliação de riscos',
    affiliatedFairData: 'Dados da feira afiliada',
    type: 'Tipo',
    schoolInstitution: 'Escola/Instituição',
    responsibleStudent: 'Estudante responsável',
    subscribed: 'Inscrito',
    openRegistrations: 'Em aberto',
    revisionRegistrations: 'Em revisão',
    inProgress: 'Em andamento',
    waitingPayment: 'Aguardando pagamento',
    effectivatedRegistrations: 'Efetivado',
    registrationComplete: 'Inscrição efetivada',
    registrationCompleteText: 'Sua inscrição foi efetivada com sucesso. Agradecemos a sua participação e nos vemos na MOSTRATEC-LIBERATO! ',
    registrationCanceled: 'Inscrição cancelada',
    registrationCanceledText: 'A inscrição do projeto foi cancelada.',
    sketch: 'Rascunho',
    pendingAcceptStatus: 'Aguardando aceite',
    pendingRevision: 'Aguardando revisão',
    pendingAnswer: 'Aguardando resposta',
    underReview: 'Em revisão',
    reviewAccepted: 'Revisão aceita',
    reviewDenied: 'Revisão negada',
    sentAnswer: 'Resposta enviada',
    selectFairEdition: 'Selecione a edição da feira',
    selectFairType: 'Selecione o tipo da feira',
    both: 'Ambas',
    outOf: 'de',
    educationalInstitutionCategoryLong: 'Categoria da instituição de ensino',
    registrationType: 'Tipo de inscrição',
    researchSubarea: 'Subárea de pesquisa',
    selectStatus: 'Selecione o status',
    selectCountry: 'Selecione o País',
    selectState: 'Selecione o Estado',
    selectCity: 'Selecione a Cidade',
    selectSchoolInstitution: 'Selecione a escola/instituição',
    selectAmongPublicAndPrivate: 'Selecione entre pública e privada',
    selectTeachingLevel: 'Selecione o nível de ensino',
    selectRegisterType: 'Selecione o tipo de inscrição',
    selectResearchArea: 'Selecione a área de pesquisa',
    selectResearchSubarea: 'Selecione a subárea de pesquisa',
    selectStudent: 'Selecione o estudante',
    selectProjectAdvisor: 'Selecione o orientador do projeto',
    selectProjectCoAdvisor: 'Selecione o coorientador do projeto',
    microbiology: 'Biologia Celular e Molecular, Microbiologia',
    bioquimics: 'Bioquímica e Química',
    animalPlantsSciences: 'Ciências Animais e de Plantas',
    ambientalSciences: 'Ciências Ambientais',
    planetarySciences: 'Ciências Planetárias, Terrestres e Matemática e Física',
    educationHumanities: 'Educação e Humanidades',
    sanitaryEngineering: 'Engenharia Ambiental e Sanitária',
    materialsAndEngineering: 'Engenharia e Materiais',
    electricEngineering: 'Engenharia Elétrica',
    sketchDescription: 'A inscrição ainda está sendo preenchida pelo(s) autor(es). No momento, não é possível visualizar seu formulário.',
    waitingPaymentDescription: 'Aguardando o pagamento da taxa de inscrição para efetuar a inscrição.',
    registrationCompleteDescription: 'O pagamento foi aprovado e a inscrição efetuada com sucesso.',
    pendingRevisionDescription: 'A feira afiliada aceitou a inscrição do projeto e será revisado pelo Comitê de Revisão Científica - CRC',
    underReviewDescription: 'Em processo de revisão por parte do Comitê de Revisão Científica - CRC',
    reviewAcceptedDescription: 'A inscrição do projeto obteve aprovação pelo CRC.',
    reviewDeniedDescription: 'A inscrição do projeto não obteve aprovação pelo CRC.',
    pendingAnswerDescription: 'Aguardando o envio de e-mail com a atualização de status.',
    sentAnswerDescription: 'A resposta da revisão desse projeto foi enviada via e-mail',
    pendingAcceptDescription: 'O projeto está aguardando o aceite da feira afiliada.',
    notAcceptDescription: 'A inscrição do projeto foi negada pela feira afiliada.',
    disabledDescription: 'O projeto inscrito está desativado.',
    loading: 'Carregando...',
    noStatus: 'N/A',
    internship: 'Estágio',
    yae: 'EJA',
    ordinalFirst: '1º',
    ordinalSecond: '2º',
    ordinalThird: '3º',
    ordinalFourth: '4º',
    ordinalFifth: '5º',
    ordinalSixth: '6º',
    ordinalSeventh: '7º',
    ordinalEighth: '8º',
    ordinalNinth: '9º',
    noneMale: 'Nenhum',

    materialsEngineeringTransports:
      subAreas.materialsEngineering.materialsEngineeringTransports.text,
    materialsEngineeringAeronautics:
      subAreas.materialsEngineering.materialsEngineeringAeronautics.text,
    materialsEngineeringConstruction:
      subAreas.materialsEngineering.materialsEngineeringConstruction.text,
    materialsEngineeringSafety: subAreas.materialsEngineering.materialsEngineeringSafety.text,
    materialsEngineeringProductDev:
      subAreas.materialsEngineering.materialsEngineeringProductDev.text,
    materialsEngineeringMaterialsScience:
      subAreas.materialsEngineering.materialsEngineeringMaterialsScience.text,
    materialsEngineeringOthers: subAreas.materialsEngineering.materialsEngineeringOthers.text,

    eletricEngineeringGeneral: subAreas.eletricEngineering.eletricEngineeringGeneral.text,
    eletricEngineeringEnergy: subAreas.eletricEngineering.eletricEngineeringEnergy.text,
    eletricEngineeringEletricDrives:
      subAreas.eletricEngineering.eletricEngineeringEletricDrives.text,
    eletricEngineeringRenewableEnergy:
      subAreas.eletricEngineering.eletricEngineeringRenewableEnergy.text,
    eletricEngineeringOthers: subAreas.eletricEngineering.eletricEngineeringOthers.text,

    eletronicEngineeringGeneral: subAreas.eletronicEngineering.eletronicEngineeringGeneral.text,
    eletronicEngineeringSystemProcesses:
      subAreas.eletronicEngineering.eletronicEngineeringSystemProcesses.text,
    eletronicEngineeringComunication:
      subAreas.eletronicEngineering.eletronicEngineeringComunication.text,
    eletronicEngineeringIndustrialAutomation:
      subAreas.eletronicEngineering.eletronicEngineeringIndustrialAutomation.text,
    eletronicEngineeringRobotics: subAreas.eletronicEngineering.eletronicEngineeringRobotics.text,
    eletronicEngineeringOthers: subAreas.eletronicEngineering.eletronicEngineeringOthers.text,

    mechanicalEngineeringGeneral: subAreas.mechanicalEngineering.mechanicalEngineeringGeneral.text,
    mechanicalEngineeringIndustrialEng:
      subAreas.mechanicalEngineering.mechanicalEngineeringIndustrialEng.text,
    mechanicalEngineeringVehicleDev:
      subAreas.mechanicalEngineering.mechanicalEngineeringVehicleDev.text,
    mechanicalEngineeringProductDev:
      subAreas.mechanicalEngineering.mechanicalEngineeringProductDev.text,
    mechanicalEngineeringOthers: subAreas.mechanicalEngineering.mechanicalEngineeringOthers.text,

    ambientalSciencesAirPolution: subAreas.ambientalSciences.ambientalSciencesAirPolution.text,
    ambientalSciencesWaterPoluition:
      subAreas.ambientalSciences.ambientalSciencesWaterPoluition.text,
    ambientalSciencesSoilContamination:
      subAreas.ambientalSciences.ambientalSciencesSoilContamination.text,
    ambientalSciencesAmbientalToxicology:
      subAreas.ambientalSciences.ambientalSciencesAmbientalToxicology.text,
    ambientalSciencesOthers: subAreas.ambientalSciences.ambientalSciencesOthers.text,

    animalSciencesBioDev: subAreas.animalSciences.animalSciencesBioDev.text,
    animalSciencesEco: subAreas.animalSciences.animalSciencesEco.text,
    animalSciencesAnimalCreation: subAreas.animalSciences.animalSciencesAnimalCreation.text,
    animalSciencesAnimalPatologies: subAreas.animalSciences.animalSciencesAnimalPatologies.text,
    animalSciencesAgronomy: subAreas.animalSciences.animalSciencesAgronomy.text,
    animalSciencesMorfology: subAreas.animalSciences.animalSciencesMorfology.text,
    animalSciencesPhotosynthesis: subAreas.animalSciences.animalSciencesPhotosynthesis.text,
    animalSciencesPlantFisiology: subAreas.animalSciences.animalSciencesPlantFisiology.text,
    animalSciencesPlantEvolutionTeory:
      subAreas.animalSciences.animalSciencesPlantEvolutionTeory.text,
    animalSciencesOthers: subAreas.animalSciences.animalSciencesOthers.text,

    bioquimicsGeneral: subAreas.bioquimics.bioquimicsGeneral.text,
    bioquimicsStructural: subAreas.bioquimics.bioquimicsStructural.text,
    bioquimicsMetabolism: subAreas.bioquimics.bioquimicsMetabolism.text,
    bioquimicsFoods: subAreas.bioquimics.bioquimicsFoods.text,
    bioquimicsOrganic: subAreas.bioquimics.bioquimicsOrganic.text,
    bioquimicsInorganic: subAreas.bioquimics.bioquimicsInorganic.text,
    bioquimicsAnalytic: subAreas.bioquimics.bioquimicsAnalytic.text,
    bioquimicsChemistry: subAreas.bioquimics.bioquimicsChemistry.text,
    bioquimicsChemistryEng: subAreas.bioquimics.bioquimicsChemistryEng.text,
    bioquimicsFisChem: subAreas.bioquimics.bioquimicsFisChem.text,
    bioquimicsOthers: subAreas.bioquimics.bioquimicsOthers.text,

    biologyMolecular: subAreas.biology.biologyMolecular.text,
    biologyGenetics: subAreas.biology.biologyGenetics.text,
    biologyImunology: subAreas.biology.biologyImunology.text,
    biologyAntibiotics: subAreas.biology.biologyAntibiotics.text,
    biologyAntimicrobians: subAreas.biology.biologyAntimicrobians.text,
    biologyBac: subAreas.biology.biologyBac.text,
    biologyVirus: subAreas.biology.biologyVirus.text,
    biologyMicology: subAreas.biology.biologyMicology.text,
    biologyOthers: subAreas.biology.biologyOthers.text,

    socialSciencesHist: subAreas.socialSciences.socialSciencesHist.text,
    socialSciencesPsic: subAreas.socialSciences.socialSciencesPsic.text,
    socialSciencesSoc: subAreas.socialSciences.socialSciencesSoc.text,
    socialSciencesAnt: subAreas.socialSciences.socialSciencesAnt.text,
    socialSciencesEconomy: subAreas.socialSciences.socialSciencesEconomy.text,
    socialSciencesPol: subAreas.socialSciences.socialSciencesPol.text,
    socialSciencesAdm: subAreas.socialSciences.socialSciencesAdm.text,
    socialSciencesOthers: subAreas.socialSciences.socialSciencesOthers.text,

    planetarySciencesClimatology: subAreas.planetarySciences.planetarySciencesClimatology.text,
    planetarySciencesGeofisics: subAreas.planetarySciences.planetarySciencesGeofisics.text,
    planetarySciencesMineralogy: subAreas.planetarySciences.planetarySciencesMineralogy.text,
    planetarySciencesPaleontology: subAreas.planetarySciences.planetarySciencesPaleontology.text,
    planetarySciencesPlanetary: subAreas.planetarySciences.planetarySciencesPlanetary.text,
    planetarySciencesGeology: subAreas.planetarySciences.planetarySciencesGeology.text,
    planetarySciencesSismology: subAreas.planetarySciences.planetarySciencesSismology.text,
    planetarySciencesOceangraphy: subAreas.planetarySciences.planetarySciencesOceangraphy.text,
    planetarySciencesAlgebra: subAreas.planetarySciences.planetarySciencesAlgebra.text,
    planetarySciencesMathAn: subAreas.planetarySciences.planetarySciencesMathAn.text,
    planetarySciencesApMath: subAreas.planetarySciences.planetarySciencesApMath.text,
    planetarySciencesGeometry: subAreas.planetarySciences.planetarySciencesGeometry.text,
    planetarySciencesProb: subAreas.planetarySciences.planetarySciencesProb.text,
    planetarySciencesAtoms: subAreas.planetarySciences.planetarySciencesAtoms.text,
    planetarySciencesBioFisics: subAreas.planetarySciences.planetarySciencesBioFisics.text,
    planetarySciencesElectronicInstrumentation:
      subAreas.planetarySciences.planetarySciencesElectronicInstrumentation.text,
    planetarySciencesMagnetism: subAreas.planetarySciences.planetarySciencesMagnetism.text,
    planetarySciencesParticleFisics:
      subAreas.planetarySciences.planetarySciencesParticleFisics.text,
    planetarySciencesOptics: subAreas.planetarySciences.planetarySciencesOptics.text,
    planetarySciencesAcustics: subAreas.planetarySciences.planetarySciencesAcustics.text,
    planetarySciencesAstronomy: subAreas.planetarySciences.planetarySciencesAstronomy.text,
    planetarySciencesOthers: subAreas.planetarySciences.planetarySciencesOthers.text,

    humanitiesKnowlageTeory: subAreas.humanities.humanitiesKnowlageTeory.text,
    humanitiesLang: subAreas.humanities.humanitiesLang.text,
    humanitiesArt: subAreas.humanities.humanitiesArt.text,
    humanitiesFilo: subAreas.humanities.humanitiesFilo.text,
    humanitiesOthers: subAreas.humanities.humanitiesOthers.text,

    sanitaryEngineeringBioremediation:
      subAreas.sanitaryEngineering.sanitaryEngineeringBioremediation.text,
    sanitaryEngineeringEcosytem: subAreas.sanitaryEngineering.sanitaryEngineeringEcosytem.text,
    sanitaryEngineeringResourses: subAreas.sanitaryEngineering.sanitaryEngineeringResourses.text,
    sanitaryEngineeringRecycling: subAreas.sanitaryEngineering.sanitaryEngineeringRecycling.text,
    sanitaryEngineeringAmbientalPolitcs:
      subAreas.sanitaryEngineering.sanitaryEngineeringAmbientalPolitcs.text,
    sanitaryEngineeringAmbientalEducation:
      subAreas.sanitaryEngineering.sanitaryEngineeringAmbientalEducation.text,
    sanitaryEngineeringOthers: subAreas.sanitaryEngineering.sanitaryEngineeringOthers.text,

    healthSciencesDiagnostic: subAreas.healthSciences.healthSciencesDiagnostic.text,
    healthSciencesEpid: subAreas.healthSciences.healthSciencesEpid.text,
    healthSciencesGenetics: subAreas.healthSciences.healthSciencesGenetics.text,
    healthSciencesFisiology: subAreas.healthSciences.healthSciencesFisiology.text,
    healthSciencesWorkHealth: subAreas.healthSciences.healthSciencesWorkHealth.text,
    healthSciencesPrevention: subAreas.healthSciences.healthSciencesPrevention.text,
    healthSciencesNutrition: subAreas.healthSciences.healthSciencesNutrition.text,
    healthSciencesBioMedicine: subAreas.healthSciences.healthSciencesBioMedicine.text,
    healthSciencesNursing: subAreas.healthSciences.healthSciencesNursing.text,
    healthSciencesOdontology: subAreas.healthSciences.healthSciencesOdontology.text,
    healthSciencesFisicEducation: subAreas.healthSciences.healthSciencesFisicEducation.text,
    healthSciencesOthers: subAreas.healthSciences.healthSciencesOthers.text,

    computacionalSciencesComputacionalEng:
      subAreas.computacionalSciences.computacionalSciencesComputacionalEng.text,
    computacionalSciencesAlgoritm:
      subAreas.computacionalSciences.computacionalSciencesAlgoritm.text,
    computacionalSciencesAI: subAreas.computacionalSciences.computacionalSciencesAI.text,
    computacionalSciencesNet: subAreas.computacionalSciences.computacionalSciencesNet.text,
    computacionalSciencesComputacionalScience:
      subAreas.computacionalSciences.computacionalSciencesComputacionalScience.text,
    computacionalSciencesGraphs: subAreas.computacionalSciences.computacionalSciencesGraphs.text,
    computacionalSciencesSoftwareEng:
      subAreas.computacionalSciences.computacionalSciencesSoftwareEng.text,
    computacionalSciencesComputerSystems:
      subAreas.computacionalSciences.computacionalSciencesComputerSystems.text,
    computacionalSciencesProgramming:
      subAreas.computacionalSciences.computacionalSciencesProgramming.text,
    computacionalSciencesOthers:
      subAreas.computacionalSciences.computacionalSciencesOthers.text,

    approvedRegistration: 'Inscrição aprovada',
    reprovedRegistration: 'Inscrição reprovada',
    on: 'em',
    at: 'às',
    by: 'por',
    notInformed: 'Não informado',
    projectRegistrationSolicitations: 'Solicitações de inscrição de projetos',
    noResearchAreasProvided: 'Não foram informadas áreas de pesquisa',
    failedToAcceptProject: 'Falha ao avaliar o projeto. Por favor, tente novamente mais tarde',
    waitingReply: 'Aguardando resposta',
    waitingEmailSent: 'Aguardando o envio de e-mail',
    requestApproved: 'Projeto revisado!',
    sendEmailDescription: 'Quando desejar notificar o(s) alunos(s) sobre seus status, basta clicar em “enviar e-mail” e um e-mail orientará os usuários a acessar a plataforma Mostratec e verificar seu novo status.',
    sendEmail: 'ENVIAR E-MAIL',
    emailSent: 'E-mail enviado',
    emailSentStudents: 'O e-mail de retorno para os estudantes foi enviado com sucesso!',
    statusUpdateAfterAnalysis: 'O status das solicitações será atualizado de acordo com a análise realizada.',
    presentationLink: 'Link do vídeo de apresentação',
    replySentByEmail: 'Resposta enviada por email',
    sentEmailDescription: 'Projeto revisado! O e-mail notificando notificar o(s) alunos(s) e responsável pela feira afiliada do status já foi enviado. Agora o(s) alunos(s) ou/e Feira afiliada poderão acessar a plataforma Mostratec, verificar seu novo status e gerenciar inscrições.',
    choose: 'Selecionar',
    all: 'todos',
    draft: 'Rascunho',
    highFinalists: 'Finalistas Ensino Médio',
    middleFinalists: 'Finalistas Ensino Fundamental',
    childishFinalists: 'Finalistas Ensino Infantil',
    noAwards: 'Este projeto ainda não possui premiações',
    newStatus: 'Novo status',
    registrationStatus: 'Situação da inscrição',
    noData: 'Não há dados!',
    succesfullySaved: 'Salvo com sucesso!',
    inputValidUrl: 'Insira uma URL válida',
    loadingMightTakeWhile: 'Carregando... Pode demorar alguns minutos',
    presentationVideoInformation: 'Você pode subir tanto o seu vídeo em arquivo ou como link pelo YouTube. Você tem até o fim do prazo de inscrição para enviá-lo.',
    documentsLimit100Videos300: 'Limite de 60MB para documentos e 300MB para videos, por arquivo',
    videoFormatMustBeMP4: 'O formato do vídeo deve ser MP4',
    admProjects: 'Painel administrativo',
    arts: 'Artes',
    biology: 'Biologia',
    education: 'Educação e pedagogia',
    eletronic: 'Elêtronica, informática e robótica',
    engineering: 'Engenharia',
    filosofy: 'Filosofia',
    phisics: 'Física',
    geography: 'Geografia',
    history: 'História',
    inclusion: 'Inclusão, acessibilidade e tecnologia',
    chemistry: 'Química',
    portuguese: 'Língua portuguesa, língua estrangeira e literatura',
    math: 'Matemática',
    envirornment: 'Meio ambiente',
    health: 'Saúde',
    sociology: 'Sociologia',
    other: 'Outras',
    biology: 'Biologia Celular e Molecular, Microbiologia',
    bioquimics: 'Bioquímica e Química',
    animalSciences: 'Ciências Animais e de Plantas',
    ambientalSciences: 'Ciências Ambientais',
    computacionalSciences: 'Ciências da Computação',
    healthSciences: 'Ciências da Saúde',
    planetarySciences: 'Ciências Planetárias, Terrestres e Matemática e Física',
    humanities: 'Educação e Humanidades',
    sanitaryEngineering: 'Engenharia Ambiental e Sanitária',
    materialsEngineering: 'Engenharia e Materiais',
    eletricEngineering: 'Engenharia Elétrica',
    eletronicEngineering: 'Engenharia Eletrônica',
    mechanicalEngineering: 'Engenharia Mecânica',
    socialSciences: 'História e Ciências Sociais',
    projectRanking: 'Ranking de projetos',
    indicators: 'Indicadores',
    mostratecOverview: 'Visão geral Mostratec',
    subtitleRS: 'Projetos inscritos pelo Rio Grande do Sul',
    subtitleBR: 'Projetos inscritos pelo Brasil',
    subtitleEX: 'Projetos inscritos estrangeiros',
    publicSchools: 'Escolas públicas',
    privateSchools: 'Escolas privadas',
    NumberParticipatingProjects: 'Quantidade de projetos participantes:',
    selectStateBeforeSave: 'Selecione um status antes de salvar!',
    projectCode: 'Código do projeto',
    projectApplicationPeriodClosed: 'Período de inscrição de projetos encerrado!',
    errorLoadingInformation: 'Erro ao carregar informações',
    institutionSchoolPhone: 'Telefone da instituição/escola (Somente números)',
    enterInstitutionSchoolPhone: 'Insira o telefone (Somente números)',
    saveAndContinue: 'Salvar e continuar',
    identificationNumber: 'Número de identificação',
    initialRegisterIdentificationNumber: 'Insira seu número de identificação',
    checkYourProjectNewResearchSubarea: 'Assinale a nova subárea de pesquisa de seu projeto',
    youCanADDTheVideo: 'Você poderá adicionar o link do vídeo até o término das inscrições',
    orEnterTheYoutubeLink: 'Ou insira o link do vídeo pelo youtube no campo abaixo.',
    enterPresentationLink: 'Insira o link para o video da apresentação do projeto',
    projectSevenFileFormat: 'Para continuidade de projetos adicione o Formulário 7. O formato do arquivo deve ser em PDF',
    formRiskAssessment: 'Para avaliação de riscos adicione o Formulário 3.O formato do arquivo deve ser em PDF',
    formQualifiedScientist: 'Para Cientista qualificado adicione o Formulário 2.O formato do arquivo deve ser em PDF',
    enterUserEmailToPasswordRecover: 'Informe o e-mail do usuário para que seja efetuada a recuperação de senha. Após clicar em "enviar", verifique sua caixa de entrada.',
    enterRecoverEmail: 'Insira o e-mail de recuperação',
    confirmYourEmail: 'Confirme seu e-mail',
    emailSuccessfullySent: 'E-mail enviado com sucesso!',
    passwordRecoveryEmailSent: 'E-mail de redefinição de senha enviado',
    passwordRecoveryEmailSuccessfullySentCheckEmailChangePassword: 'O e-mail para redefinição de senha foi enviado com sucesso para sua caixa de entrada. Verifique seu e-mail e altere a senha.',
    acceptanceRequired: 'Para utilizar a plataforma, você deve aceitar o',
    dataProtectionTerm: 'Termo de Consentimento para Tratamento de Dados Pessoais.',
    followTheLink: 'Clique no link abaixo para prosseguir.',
    attention: 'Atenção!',
    fileFormatPDF: 'O formato do arquivo deve ser em PDF. Você poderá adicionar o arquivo após o envio de sua inscrição, se ainda não o possuir.',
    optionalLabel: '(Opcional)',
    azureMessageFail: 'Por gentileza, tente mais tarde, pois o serviço de envio de documentos está indisponível.',
    errorLabel: 'Erro',
    errorDuringSaving: 'Erro ao salvar',
    successfullRequest: 'Solicitação enviada com sucesso! Você será redirecionado para a tela inicial da inscrição!',
    projectAlreadySaved: 'Projeto já salvo!',
    schoolSaved: 'Escola já salva',
    researchAreaSingular: 'Área de pesquisa',
    delete: 'Excluir',
    enrollment: 'Inscrição',
    value: 'Taxa',
    food: 'Alimentação',
    finalValue: 'Valor final',
    infoAboutTypePayment: 'De que forma você gostaria de efetuar o pagamento?',
    payOnAccreditation: 'Pagar no credenciamento',
    registrationFeeAndFood: 'Taxas de inscrição e Alimentação',
    nationalFormPaymentInfoL1: 'Observações',
    nationalFormPaymentInfoL2: '1) Devido ao patrocínio do SEBRAE como parceiro correalizador da MOSTRATEC em 2024, participantes de escolas públicas contam com isenção da taxa de inscrição e participantes de escolas privadas contam com 50% de desconto, já calculado no valor da taxa mostrada abaixo (não inclui refeições).',
    nationalFormPaymentInfoL3: '2) Se o valor Total gerado para pagamento for ZERO, (projeto de escolas públicas e sem refeições), você deverá escolher a opção "Pagar no Credenciamento".',
    nationalFormPaymentInfoL4: '',
    foreignFormPaymentInfoL1: '',
    foreignFormPaymentInfoL2: '',
    foreignFormPaymentInfoL3: '',
    foreignFormPaymentInfoL4: '',
    registrationFee: 'Taxa de inscrição',
    foreign: 'Estrangeiro',
    noMeal: 'Nenhuma refeição',
    oneMeals: '1 refeição',
    twoMeals: '2 refeições',
    threeMeals: '3 refeições',
    fourMeals: '4 refeições',
    fiveMeals: '5 refeições',
    sixMeals: '6 refeições',
    sevenMeals: '7 refeições',
    approvalPayment: 'o pagamento deverá ser realizado após a aprovação',
    chooseSchoolType: 'Escolha o tipo de escola',
    studentMustBeSelected: 'Pelo menos um estudante deve estar selecionado',
    selectNumberOfMeals: 'Selecione a quantidade de refeições',
    participationNotSelected: 'Participação não selecionada',
    selectPaymentMethod: 'Selecione a forma de pagamento',
    discountPayment: 'Desconto não pode ser maior que o valor total',
    saveInformation: 'Salvar',
    participateInThisProject: 'Sim, eu participo deste projeto',
    alreadyParticipateInAnotherProject: 'Eu já participo de outro projeto',
    willNotParticipateInMostratec: 'Não participarei da Mostratec',
    cannotPayMercadoPago: 'Não é possível pagar com Mercado Pago, Total = R$ 0,00',
    finish: 'Finalizar',
    projects: 'Projetos',
    placeholderSearchBar: 'Pesquise por nome do responsável, nome do projeto ou id do projeto',
    buttonAllTickets: 'Imprimir todas as etiquetas',
    commitNote: 'Nota de empenho (somente prefeituras)',
    rulesOfConduct: 'Contrato de convivência',
    alreadyInUse: 'Este CPF já está em uso',
    warningForMultiple: 'AVISO: Cadastre apenas UM projeto por sessão no navegador. Abrir mais de uma aba / janela do navegador causará conflito/mistura de dados entre os projetos e inviabilizará a inscrição.',
    cohabitationAgreementRequired: 'Necessário o upload do contrato de convivência.',
    sebraeTitle: 'Inscreva-se também na CATEGORIA CULTURA EMPREENDEDORA E INOVAÇÃO',
    //sebraeQuestion1: 'Você, estudante do ensino médio ou técnico, residente no Brasil, tem a oportunidade de participar de duas categorias simultaneamente, além de concorrer na sua área de pesquisa, poderá disputar a categoria de CULTURA EMPREENDEDORA E INOVAÇÃO, que pretende reconhecer os melhores projetos com potencial empreendedor. Para isso, o SEBRAE RS, correalizador da MOSTRATEC 2024, oferece uma formação em canvas e pitch, rápida, síncrona ou assíncrona e online, para mais informações, leia o regulamento.',
    sebraeQuestion1: 'Você, estudante do ensino médio ou técnico, residente no Brasil, tem a oportunidade de participar de duas categorias simultaneamente; além de concorrer na sua área de pesquisa, poderá disputar a categoria de CULTURA EMPREENDEDORA E INOVAÇÃO, que pretende reconhecer os melhores projetos com potencial empreendedor e de negócios inovadores. Para isso, o SEBRAE RS, correalizador da MOSTRATEC 2024, oferece uma formação gratuita em Cultura Empreendedora e Inovação no formato online, através da Plataforma Google Meet. Para concorrer na categoria, ao menos um participante da equipe do projeto deverá participar da formação. Para mais informações, acesse o Regulamento de Participação no site www.mostratec.com.br/regulamento-da-mostratec/.',
    sebraeQuestion2: 'Selecione abaixo se você quer concorrer na categoria transversal CULTURA EMPREENDEDORA E INOVAÇÃO:',
    sebraeResponse0: '',
    sebraeResponse1: 'Tenho interesse',
    sebraeResponse2: 'Não tenho interesse',
    categoryFormSelection: 'Nível de Ensino/Categoria',
    paymentDateLimit: 'A data limite para pagamento via Mercado Pago é dia '
  }
}
