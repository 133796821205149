module.exports = {
  es: {
    emailOrPassword: 'Verifique su correo electrónico/contraseña',
    emailNotConfirmed: 'Su correo electrónico de acceso aún no ha sido confirmado. Por favor, ingrese su correo electrónico y confírmelo para iniciar sesión.',
    myPanelFairVacancies: 'Mi panel de feria',
    forgotPassword: '¿Olvidaste tu contraseña?',
    clickHereLogin: 'Haga clic aquí',
    toRecoveryLogin: 'para recuperarlo',
    buttonLogin: 'Entra',
    projectData: 'Datos del proyecto',
    abstract: 'Resumen',
    projectSummary: 'Resumen del proyecto',
    ResultsLabel: 'Resultados y conclusiones',
    researchSubAreas: 'Subáreas de investigación',
    rememberMeLogin: 'Acordarse de mi',
    emailLogin: 'Introduce tu correo electrónico',
    passwordLogin: 'Ingresa tu contraseña',
    passwordLoginLabel: 'Contraseña',
    returnInformationLogin: 'Regrese a la página de información.',
    initialRegisterHeader1: 'Ingrese los siguientes datos para preinscribirse en Mostratec',
    initialRegisterHeader2: 'Digital. Después de completar todos los campos, confirme su registro accediendo a la',
    initialRegisterHeader3: 'casilla de correo electrónico del correo electrónico registrado en el formulario a continuación. Después de',
    initialRegisterHeader4: 'confirmar los datos, podrá acceder al sistema para completar el',
    initialRegisterHeader5: 'formulario de registro y rastrear el estado de su',
    initialRegisterHeader6: 'solicitud.',
    allFieldsAreObligatory: 'Todos los campos son obligatorios.',
    ResearchPlan: 'Plan de investigación',
    ResearchPlanInfo: '',
    videoLink: 'Enlace de video',
    bannerLink: 'Enlace de banner',
    projectBannerLink: 'enlace del banner del proyecto',
    projectVideoLink: 'enlace del video del proyecto',
    placeholderBannerLink: 'Inserta el enlace al banner del proyecto',
    videoLinkInsert: 'Insertar o proyectar enlace de presentación',
    genderedAdvisor: 'Orientador(a)',
    genderedCoAdvisor: 'Coorientador(a)',
    assignmentPresentation: 'Presentación del trabajo',
    humanBeingsNoun: 'Seres humanos',
    risksEvaluation: 'Evaluación de riesgos',
    affiliatedFairData: 'Datos de la feria afiliada',
    type: 'Tipo',
    schoolInstitution: 'Escuela/Institución', 
    responsibleStudent: 'Estudiante responsable',
    subscribed: 'Inscrito',
    openRegistrations: 'Abierto',
    revisionRegistrations: 'Under review',
    inProgress: 'In Progress',
    waitingPayment: 'Esperando el pago',
    registrationComplete: 'Registro completo',
    registrationCanceled: 'Suscripción cancelada',
    registrationCanceledText: 'El registro del proyecto ha sido cancelado',
    sketch: 'Bosquejo',
    pendingAcceptStatus: 'Esperando aceptar',
    pendingRevision: 'Esperando revisión',
    pendingAnswer: 'Esperando revisión',
    underReview: 'Bajo revisión',
    reviewAccepted: 'Revisión aceptada',
    reviewDenied: 'Revisión denegada',
    requestRegistration: 'Solicitar registro',
    noProject: 'Aún no tienes ningún proyecto registrado en Mostratec. ¡Esté atento a los plazos y la tarifa de solicitud y haga clic en Aplicar para comenzar!',
    reviewDeniedDescription: 'La solicitud del proyecto no fue aprobada por el CRC.',
    sentAnswer: 'Respuesta enviada',
    videoFile: 'Archivo de vídeo',
    presentationVideo: 'Video de presentación',
    obrigatoryFiles: 'Archivos Obrigatorios',
    documents: 'Documentos',
    heartyWelcome: 'Bienvenido!',
    trackEntries: 'Seguimiento de sus entradas.',
    ThemeLabel: 'Tema',
    ThemePlaceholder: 'Ingrese el tema de su investigación',
    ThemeTextInfo: 'Los siguientes campos deben contener objetivos y una descripción concisa de los elementos de su plan de investigación.',
    JustificationLabel: 'Justificación',
    JustificationPlaceholder: 'Ingrese la justificación de la investigación',
    JustificationCancel: 'Insira a justificativa de cancelamento',
    requiredJustify: 'La justificación es obligatoria',
    selectionSituation: 'Seleccione el estado del proyecto',
    JustificationTextInfo_parOne: 'La justificación, en un proyecto de investigación, es la convicción de que el trabajo de investigación es fundamental para ser eficaz y relevante para la sociedad o para algunas personas que se beneficiarán de la investigación. Debe presentar cómo la información generada por la encuesta es útil y para quién, cómo se agregará y qué decisiones se pueden tomar con base en los datos generados.',
    JustificationTextInfo_parTwo: 'La justificación resalta la importancia del tema a estudiar, justifica la necesidad de llevar a cabo tal emprendimiento y conduce a la formulación del problema. Cabe destacar en el trabajo que existen otros trabajos que resaltan la importancia del tema de investigación y estos deben ser referenciados.',
    ProblemLabel: 'Problema',
    ProblemPlaceholder: 'Ingrese el problema de búsqueda',
    ProblemTextInfo: 'El problema surge de una situación que plantea interrogantes sobre el tema y puede ser definido por la propia experiencia del investigador o indicado por profesionales vinculados al tema. A partir de la identificación del problema, se elabora una pregunta específica para ser respondida por la investigación, estableciendo así un foco de estudio para dar respuesta a la pregunta. Las preguntas de investigación deben tener respuestas que deben obtenerse con metodología científica y / o tecnológica / de ingeniería.',
    HypothesisLabel: 'Supuestos (opcional)',
    HypothesisPlaceholder: 'Ingrese la hipótesis de investigación',
    HypothesisTextInfo_parOne: 'La hipótesis es una posible respuesta a la pregunta planteada en el problema del proyecto de investigación. Según Bello (2009, p. 23), es una pre-solución al problema planteado en el tema elegido para la investigación.',
    HypothesisTextInfo_parTwo: 'Dependiendo de la elección metodológica realizada, la investigación no necesita necesariamente establecer hipótesis. Para la investigación tipo encuesta o la investigación tecnológica, por ejemplo, la hipótesis no se convierte en un elemento formal obligatorio.',
    continuityFileInsert: 'Insertar el documento de continuidad',
    ObjectivesLabel: 'Objetivos',
    ObjectivesPlaceholder: 'Ingrese los objetivos de búsqueda',
    ObjectivesTextInfo: 'La definición de objetivos determina lo que el investigador quiere lograr con la realización del trabajo de investigación y debe corresponder a las preguntas propuestas.',
    ObjectLabel: 'Objetos',
    ObjectPlaceholder: 'Ingresar objetos de búsqueda',
    ObjectTextInfo: 'Eso es lo que se hará. A partir del objeto se define el área de investigación y la clasificación en investigación científica o tecnológica.',
    BibliographicReviewLabel: 'Revisión bibliográfica',
    BibliographicReviewPlaceholder: 'Insertar la revisión bibliográfica de la investigación',
    BibliographicReviewTextInfo: 'En este momento, el investigador busca, localiza y revisa la literatura donde obtendrá material bibliográfico que subsidiará la temática del trabajo de investigación, como libros, artículos científicos, revistas, periódicos, normas técnicas, legislación, etc.',
    MethodologyLabel: 'Metodología',
    MethodologyPlaceholder: 'Ingrese la metodología de la encuesta',
    MethodologyTextInfo_parOne: 'La Metodología, también denominada Materiales y Métodos, es la descripción de la estrategia a adoptar, que contiene todos los pasos y procedimientos adoptados para llevar a cabo la investigación y alcanzar los objetivos. ',
    MethodologyTextInfo_parTwo: 'Tras realizar las opciones, se describe detalladamente cómo se llevará a cabo la investigación (cómo se recogerán los datos, cuestionarios, entrevistas, muestras, etc.) y cómo se llevará a cabo el análisis de los datos que se obtendrán. Se debe incluir el cronograma, los recursos que se necesitarán y la evaluación.',
    CronogramLabel: 'Cronograma',
    CronogramPlaceholder: 'Ingrese el horario de la encuesta',
    CronogramTextInfo: 'Es una adecuada planificación del tiempo que tendrá el investigador para realizar el trabajo, especificando las actividades a realizar.',
    DataAnalisisLabel: 'Análisis de datos',
    DataAnalisisPlaceholder: 'Ingrese al análisis de datos de la encuesta',
    DataAnalisisTextInfo: '¿Debería mostrar cómo se hará el análisis, evaluación de datos, con qué se comparará, utilizará algún método estadístico? ¿Cómo sabrás saber si los resultados son buenos o no, compararás los resultados con qué, con las especificaciones de algún estándar o con los resultados de algún autor, o con qué otro tipo de investigación?',
    ReferencesLabel: 'Referencias',
    ReferencesPlaceholder: 'Ingrese sus referencias de investigación',
    ReferencesTextInfo: 'La referencia de los documentos efectivamente citados dentro del proyecto de investigación es un elemento obligatorio para la elaboración del Proyecto.',
    affiliationPeriodClosed: '¡Periodo de afiliación cerrado!',
    ifYouMissedTheDeadline: 'Si se ha perdido la fecha límite, ¡contamos con su colaboración para fomentar la investigación científica entre los estudiantes en la próxima edición de Mostratec!',
    projectTitle: 'Título del Proyecto',
    enterTheProjectTitle: 'Ingrese el título del proyecto',
    typeOfResearchDevelop: 'Tipo de investigación desarrollada',
    researchAreaSingle: 'Área de investigación',
    isThereOtherSubarea: '¿Existe otra subárea?',
    isThereOtherSubareaPlaceholder: 'En caso afirmativo, ingrese cuál es la otra subárea',
    isYourProjectContinuity: '¿Tu proyecto está en continuidad?',
    continuityProjectInfo: 'Si el proyecto de hasta 12 meses se ha completado y ha decidido continuar con un nuevo objetivo, se considera un proyecto de continuación.',
    doesItInvolveVertebrateAnimals: '¿Involucra animales vertebrados no humanos?',
    doesItInvolveHumans: '¿Involucra a seres humanos?',
    doesItInvolveHumansInfo: 'Si su investigación involucró a seres humanos, como un cuestionario, entrevista, otros tipos de interacción, etc., debe completar el formulario 4, firmar todos los campos como se indica en el formulario y adjuntarlo al final de la solicitud.',
    doesItInvolveBiologicalSubstances: '¿Involucra sustancias biológicas?',
    doesItInvolveDangerousBiologicalSubstances: '¿Involucra sustancias biológicas potencialmente peligrosas?',
    doesItInvolveTissueFromHumans: '¿Involucra tejido de humanos o animales vertebrados?',
    doesItInvolveControlledSubstances: '¿Involucra sustancias controladas?',
    doesItInvolveDangerousSubstances: '¿Involucra sustancias y equipos peligrosos?',
    isTheResearchCarriedInInstituition: '¿La investigación se lleva a cabo en una institución de investigación o en una industria regulada?',
    isTheResearchCarriedInInstituitionInfo: 'Ejemplo: universidad, laboratorio, etc.',
    scientific: 'Científico',
    technological: 'Tecnológico',
    scientificInfo: 'Investigación Básica o Aplicada e Ingeniería.',
    technologicalInfo: 'Investigación en Ingeniería - Materialización de un producto o proceso a través de un modelo, prototipo o instalación piloto. Ejecución de una obra, fabricación de un producto, prestación de un servicio, ejecución de un proceso.',
    cellularMolecularMicrobiology: 'Biología Celular y Molecular, Microbiología',
    animalPlantSciences: 'Ciencias animales y vegetales',
    environmentalSciences: 'Ciencias Ambientales',
    healthSciences: 'Ciencias de la salud',
    educationAndHumanities: 'Educación y Humanidades',
    environmentalAndSanitaryEngineering: 'Ingeniería ambiental y sanitaria',
    electricalEngineering: 'Ingeniería Eléctrica',
    electronicEngineering: 'Ingeniería Electrónica',
    mechanicalEngineering: 'Ingeniería Mecánica',
    historyAndSocialSciences: 'Historia y Ciencias Sociales',
    checkResearchSubAreas: 'Consulte las subáreas de investigación de su proyecto:',
    molecularAndCellBiology: 'Biología Molecular y Celular;',
    genetics: 'Genética;',
    immunology: 'Inmunología;',
    antibiotics: 'Antibioticos;',
    antimicrobials: 'Antimicrobianos;',
    bacteriology: 'Bacteriología;',
    virology: 'Virología;',
    mycology: 'Micologia;',
    others: 'Otros;',
    generalBiochemistry: 'Bioquímica general;',
    metabolism: 'Metabolismo;',
    foodChemistry: 'Química de Alimentos;',
    organicChemistry: 'Química Orgánica;',
    inorganicChemistry: 'Química Inorgánica;',
    analyticalChemistry: 'Química Analítica;',
    generalChemistry: 'Química General;',
    chemicalEngineering: 'Ingeniería Química;',
    physicoChemical: 'Fisicoquímica;',
    structuralBiochemistry: 'Bioquímica estructural;',
    biologicalDevelopment: 'Desarrollo biológico (humano y animal);',
    ecology: 'Ecología;',
    animalHusbandry: 'Crianza de animales;',
    animalPhysiologyPathology: 'Fisiología y Patología Animal;',
    agricultureAgronomy: 'Agricultura/Agronomía;',
    plantMorphology: 'Morfología y desarrollo vegetal;',
    photosynthesis: 'Fotosíntesis;',
    plantPhysiology: 'Fisiología de las plantas;',
    plantEvolutionTheory: 'Teoría de la evolución de las plantas;',
    pollutionAir: 'Contaminación y calidad del aire;',
    pollutionWater: 'Contaminación y calidad del agua;',
    contaminationSoilQuality: 'Contaminación y calidad del suelo;',
    environmentalToxicology: 'Toxicología ambiental;',
    computerEngineering: 'Ingeniería en Computación;',
    algorithmsDatabase: 'Algoritmos, base de datos;',
    artificialIntelligence: 'Inteligencia artificial;',
    communicationNetworks: 'Comunicación y redes;',
    computerScienceSub: 'Ciencias de la Computación;',
    graphics: 'Gráficos;',
    softwareEngineering: 'Ingeniería de software;',
    computerSystems: 'Sistemas informáticos;',
    programmingLanguages: 'Lenguajes de programación;',
    diagnosisAndTreatmentDiseases: 'Diagnóstico y tratamiento de enfermedades;',
    epidemiology: 'Epidemiología;',
    physiologyAndPathophysiology: 'Fisiología y Fisiopatología;',
    healthAtWork: 'Salud en el trabajo;',
    preventionAndProphylaxis: 'Prevención, profilaxis;',
    nutrition: 'Nutrición;',
    biomedicine: 'Biomedicina;',
    nursing: 'Enfermería;',
    dentistry: 'Odontología;',
    physicalEducation: 'Educación Física;',
    climatology: 'Climatología, clima;',
    geochemistryGeophysics: 'Geoquímica, Geofísica;',
    mineralogy: 'Mineralogía;',
    paleontology: 'Paleontología;',
    planetarySciences: 'Ciencias planetarias;',
    geology: 'Geología;',
    seismology: 'Sismología;',
    oceanography: 'Oceanografía;',
    algebra: 'Álgebra;',
    analyticalMathematics: 'Matemática analítica;',
    aplicatedMath: 'Matemática Aplicada;',
    geometry: 'Geometría;',
    clickingHere: 'haciendo click aqui',
    enrollmentInProgress: 'Su solicitud aún no ha sido finalizada. Continúe con su registro',
    waitingPaymentText: '¡Su solicitud ha sido aceptada por el CRC! Para finalizar y participar en la edición activa de Mostratec, le pedimos que pague la cuota de inscripción.',
    probabilityStatistics: 'Probabilidad y Estadística;',
    atomsMoleculesSolids: 'Átomos, moléculas y sólidos;',
    biologicalPhysics: 'Física Biológica;',
    registrationCompleteText: 'Su suscripción ha sido completada con éxito. ¡Agradecemos su participación y nos vemos en la MOSTRATEC-LIBERATO!',
    inAnalysis: 'Un momento. Su solicitud de registro está en revisión y le enviaremos una respuesta por correo electrónico en breve.',
    instrumentationEletronics: 'Instrumentación y electrónica;',
    notAcceptedText: 'Le agradecemos su interés en participar en MOSTRATE-LIBERATO. Lamentablemente, su proyecto no fue seleccionado. Puedes volver a intentarlo el próximo año.',
    magnetismElectromagnetism: 'Magnetismo y electromagnetismo;',
    particleNuclearPhysics: 'Física nuclear y de partículas;',
    opticsLaser: 'Óptica, láser;',
    acoustics: 'Acústica;',
    theoreticalPhysicsAstronomy: 'Física teórica, astronomía;',
    theories: 'Teorías del conocimiento, Metodología, Didáctica, Pedagogía;',
    linguistics: 'Ciencia de idiomas;',
    artisticExpression: 'Expresión artística;',
    philosophyTheology: 'Filosofía y Teología;',
    bioremediation: 'Biorremediación;',
    ecosystemManagement: 'Gestión de ecosistemas;',
    landWaterForestManagement: 'Manejo de recursos terrestres, hídricos y forestales;',
    wasteManagement: 'Gestión de residuos, reciclaje;',
    environmentalPolicy: 'Política medioambiental, legislación medioambiental;',
    environmentalEducational: 'Educación ambiental;',
    transportEngineering: 'Ingeniería de transporte;',
    aerospaceAeronauticalEngineering: 'Ingeniería aeroespacial y aeronáutica, aerodinámica;',
    civilEngineering: 'Ingeniería civil y construcción;',
    securityEngineering: 'Ingeniería de Seguridad;',
    productDevelopment: 'Desarrollo de productos;',
    materialsScience: 'Ciencia de los Materiales;',
    generalEletric: 'Energia General;',
    generationTransmissionElectricalEnergy: 'Generación, transmisión y transformación de energía eléctrica;',
    eletricDrives: 'Accionamientos eléctricos;',
    renewableEnergy: 'Energias renovables;',
    generalElectronics: 'Electrónica general;',
    electronicSystems: 'Procesos y sistemas electrónicos;',
    communicationSystems: 'Sistemas de comunicación;',
    industrialAutomation: 'Automatización industrial;',
    robotics: 'Robótica;',
    industrialEngineering: 'Ingeniería Industrial;',
    vehicleDevelopment: 'Desarrollo de vehículos;',
    productAndProcessDevelopment: 'Desarrollo de productos y procesos;',
    history: 'Historia;',
    psychology: 'Psicología;',
    sociology: 'Sociología;',
    anthropology: 'Antropología;',
    economy: 'Economía;',
    policts: 'Política;',
    management: 'Gestión;',
    informTheDataOfTheProject: 'Informar los datos de los autores de este proyecto. El número de estudiantes disponibles para la inscripción corresponde a su respuesta en el campo.',
    numberOfStudentsInTheProject: 'Número de alumnos en el proyecto.',
    ableToParticipateInALivePresentation: '¿Podrán lo(s) estudiante(s) del proyecto participar en una presentación en vivo?',
    individual: 'Individual',
    pair: 'Par',
    trio: 'Trío',
    student: 'Estudiante ',
    studentOneInfo: 'El estudiante 1 es responsable de la preinscripción y registro del proyecto. Recuerde que es a través del inicio de sesión de pre-registro de este usuario que será posible monitorear el progreso de su registro.',
    fullName: 'Nombre completo',
    fullNameStudent: 'Nombre completo del estudiante',
    fullNamePlaceholder: 'Nombre y apellido',
    maleSingle: 'Masculino',
    femaleSingle: 'Femenino',
    sex: 'Sexo',
    birthDate: 'Fecha de nacimiento',
    responsibleName: 'Nombre de la persona responsable',
    responsiblePerson: 'Responsable',
    relationshipOfResponsible: 'Relación del responsable',
    enterThePhone: 'Inserta el teléfono',
    enterTheEmail: 'Ingrese el correo electrónico del estudiante',
    enterTheResponsibleAdultName: 'Ingrese el nombre del adulto responsable',
    enterParentRelationship: 'Ingrese la relación de lo responsable',
    registrationData: 'Datos de registro',
    levelOfKnowledgeInEnglish: 'Nivel de conocimiento en inglés',
    basic: 'Básico',
    intermediary: 'Intermediario',
    advanced: 'Avanzado',
    fluent: 'Fluido',
    doYouHaveScholarship: '¿Tienes una beca en un programa de iniciación científica junior?',
    whichProgram: '¿Cuál programa?',
    haveYouParticipatedAnyScienceFair: '¿Has asistido alguna vez a una feria de ciencias?',
    doYouHaveAnyKindOfDisability: '¿Tiene algún tipo de discapacidad?',
    none: 'Ninguna',
    physical: 'Física',
    intellectual: 'Intelectual',
    auditory: 'Auditiva',
    visual: 'Visual',
    willYouNeedAnySpecializedAssistance: '¿Necesitarás asistencia especializada?',
    architecturalAccessibility: 'Accesibilidad arquitectónica (El espacio físico de la feria ya cuenta con rampas de acceso y ascensor)',
    libraInterpreter: 'Intérprete Libra',
    reader: 'Lector',
    othersMale: 'Otros;',
    typeOfShirt: '¿Qué tipo de camiseta eliges?',
    whatSizeShirt: '¿Qué talla de camisa?',
    scholarshipCNPq: 'Beca financiada por CNPq',
    otherFemale: 'Otra',
    enterTheProgramName: 'Ingrese el nombre del programa',
    enterWhichScienceFair: 'Ingrese a qué feria de ciencias ha asistido',
    yesOfTheMostratec: 'Sí, de la Mostratec',
    notApplicable: 'No se aplica',
    shirt: 'Camiseta de manga corta',
    poloShirt: 'Camisa polo',
    p: 'P',
    m: 'M',
    g: 'G',
    gg: 'XG',
    projectsRevision: 'Revisión del proyecto',
    projectsPayment: 'Pago de registro',
    isAproved: '¿Considera este proyecto aprobado para participar en Mostratec?',
    ifAproved: 'Si lo aprueba, los estudiantes matriculados podrán pagar la cuota de participación. De lo contrario, ingrese un motivo de rechazo en los campos de opinión.',
    subscriptionAproved: '¡Sí, solicitud aprobada!',
    subscriptionRejected: '¡No, registro reprobado!',
    revision: 'Revisión',
    reviewForm1: 'Formulário de revisión 1',
    video: 'Video',
    continuity: 'Proyecto de continuidad',
    revisionName: 'Nombre responsable de la revisión',
    nameSurname: 'Nombre Apellido',
    considerations: 'Deja tus consideraciones aquí',
    opinionToInternal: 'Opinión registrada internamente en el sistema en caso de desaprobación de la solicitud del proyecto',
    opinionToStudents: 'Opinión enviada a los estudiantes en caso de desaprobación de la inscripción de su proyecto.',
    presentation: 'Presentación del proyecto',
    vertebrates: 'Animales vertebrados',
    bios: 'Sustancias biológicas potencialmente peligrosas',
    instituition: 'Institución de investigación o industria regulada',
    scientist: 'Científico calificado',
    humans: 'Seres humanos',
    humansNecessaryFile: 'Es necesario cargar el formulario de investigación con seres humanos.',
    tissues: 'Sustancias con tejidos de humanos o animales vertebrados',
    substitute: 'Este proyecto será un sustituto',
    riscs: 'Evaluación de riesgos',
    banner: 'Banner',
    startReview: 'Iniciar revisión',
    finishReview: 'Revisión terminada',
    editSituation: 'Editar situación',
    projectGrade: 'Nota del proyecto',
    giveAGrade: 'Tasa de 0 a 10',
    projectOpinion: 'Opinión del proyecto',
    internalOpinion: 'Opinión interna',
    revisionFiles: 'Revisión de archivos registrados',
    useCheckbox: 'Para facilitar su organización, marque la casilla de verificación de archivos revisados ​​antes de aprobar el proyecto.',
    institutionTitle: 'Datos de la escuela / institución',
    cell: 'Teléfono móvil',
    landline: 'Teléfono fijo (opcional)',
    email: 'Correo electrónico',
    contact: 'Contacto',
    mainContactInfo: 'La información de contacto 1 se utilizará para notificaciones relacionadas con MOSTRATEC.',
    contacts: 'Contactos',
    bestEmail: 'Mejor Correo Electrónico - (Por favor, proporcione un correo electrónico que se acceda con frecuencia, ya que este será el medio de contacto con la feria afiliada).',
    name: 'Nombre',
    contactNamePlaceholder: 'Ingrese el nombre del contacto',
    contactPhone: 'Teléfono (fijo o móvil, sólo números)',
    office: 'Posición',
    officePlaceholder: 'Ingrese el posición del contacto',
    fairName: 'Nombre de la feria',
    country: 'País',
    state: 'Estado',
    city: 'Ciudad',
    cpfCnpj: 'CPF/CNPJ',
    email: 'E-mail',
    initialRegisterCpfCnpjTextInfo: 'Informar al CPF del alumno responsable de la inscripción de proyectos.',
    initialRegisterEmailTextInfo: 'El correo electrónico ingresado anteriormente también será utilizado para acceder a la plataforma Mostratec luego de confirmar la preinscripción. Debe ser diferente al correo electrónico registrado en la feria afiliada y no puede ser utilizado en más de un proyecto.',
    initialRegisterPasswordTextInfo: 'La contraseña creada anteriormente será la contraseña que se utilizará para acceder a la plataforma Mostratec después de confirmar la preinscripción. Debe tener al menos 8 caracteres, incluidas letras minúsculas, mayúsculas, número y carácter especial (! @ # $ % & * / + - ?).',
    send: 'Enviar',
    reports: 'Reportando',
    manageSubcriptions: 'Administrar Suscripciones',
    fairSettings: 'Ajustes de la Feira',
    submitSubcriptions: 'Request suscripción',
    notifications: 'Notificaciones',
    welcome: 'Bienvenido(a)',
    editionRegistration: 'Registro de la edición',
    feesDetails: 'Tarifa',
    allEditions: 'Todas las ediciones',
    generalData: 'Registrar una edición',
    vacancies: 'Vagas',
    informations: 'Trabajos',
    editionInformations: 'Registre la información solicitada a continuación para configurar una nueva edición de la feria Mostratec.',
    activeEditionWarn: 'Al crear una nueva edición, se establecerá automáticamente como la edición activa.',
    fairStatus: 'Estado justo',
    inactive: 'Inactivo',
    active: 'Activo',
    editionId: 'Código de identificación de la edición',
    editionFairStatusInfo: 'Al activarlo, está habilitando la recepción de inscripciones para la nueva edición de la feria inscrita.',
    vinculatedFair: 'Feria vinculada',
    editionVinculatedFairInfo: 'Seleccione para qué modo justo se configurará la nueva edición.',
    editionVinculatedFairPlaceholder: 'Ej: Mostratec Júnior',
    editionName: 'Nombre de la edición',
    editionNamePlaceholder: 'Ingrese cuál será el nombre de la edición',
    year: 'Año',
    yearPlaceholder: 'Ingrese el año de emisión',
    format: 'Formato',
    editionFormatInfo: 'Selecciona en que formato se llevará a cabo la feria',
    inPerson: 'En persona',
    virtual: 'Virtual',
    hibrid: 'Híbrido',
    affiliatedFair: 'Feria afiliada',
    editionAffiliatedFairDescription: 'Los siguientes campos están relacionados con los períodos de inscripción que estarán disponibles para las ferias afiliadas.',
    mostratecAffiliationRegistrationPeriod: 'Periodo de registro de afiliación a Mostratec',
    start: 'Comienzo',
    end: 'Fin',
    mostratecAffiliationRegistrationPeriodInfo: 'Ingrese el período de inicio y finalización del período de solicitud para ferias afiliadas.',
    affiliatedFairsAnalysisPeriod: 'Periodo de análisis de ferias afiliadas',
    affiliatedFairsAnalysisPeriodInfo: 'Ingrese el período inicial y final del período de revisión para solicitudes de membresía.',
    affiliatedFairsResponsePeriod: 'Plazo de respuesta a ferias afiliadas',
    affiliatedFairsResponsePeriodInfo: 'Ingrese el período inicial y final para responder a las solicitudes de membresía.',
    mostratecProjectAfilliateFairRegistrationPeriod: 'Plazo de registro del proyecto a través de la feria afiliada a Mostratec.',
    mostratecProjectSelectionRegistrationPeriod: 'Plazo de registro del proyecto a través del proyecto de selección Mostratec',
    mostratecProjectRegistrationPeriod: 'Plazo de inscripción del proyecto a Mostratec',
    mostratecProjectSelectionRegistrationPeriodInfo: 'Informar el período inicial y final para el registro de proyectos a través del proceso de selección.',
    mostratecProjectAfilliateFairRegistrationPeriodInfo: 'Informar el plazo inicial y final para el registro de proyectos a través de la feria afiliada',
    PeriodInWhichMostratecEventWillTakePlace: 'Periodo en el que tendrá lugar el evento Mostratec',
    PeriodInWhichMostratecEventWillTakePlaceInfo: 'Ingrese el período inicial y final para responder a las solicitudes de membresía.',
    save: 'Ahorrar',
    edit: 'Editar',
    subscriptionRequest: 'Solicitud de registro',
    selectionProcess: 'Proceso de Selección',
    appraisers: 'Tasadores',
    allRequests: 'Todas las solicitudes',
    openRequests: 'Solicitudes abiertas',
    affiliatedFairs: 'Ferias afiliadas',
    acceptedRequests: 'Solicitudes aceptadas',
    rejectedRequests: 'Solicitudes rechazadas',
    search: 'Buscar',
    fairEdition: 'Edición de feria',
    fairType: 'Tipo de feria',
    subscription: 'Inscripciones',
    fairEditionPlaceholder: 'Buscar solicitudes de ferias por edición',
    fairTypePlaceholder: 'Ej: Escuela Primaria Mostratec, Mostratec Júnior',
    filter: 'Filtrar',
    subtitleDescription: 'Descripción de subtítulos',
    pendingReview: 'Revisión pendiente',
    underAnalysis: 'En análisis',
    notAccepted: 'No acepta',
    accepted: 'Aceptado',
    waitingResponse: 'Esperando respuesta',
    code: 'Código',
    requestStatus: 'Estado de la solicitud',
    countryState: 'Páis',
    organizingInstitution: 'Institución organizadora',
    schoolType: 'Tipo de escuela',
    vacanciesNumberChildish: 'Número de asientos para niños',
    vacanciesNumberElementary: 'Número de asientos para escuela primaria',
    vacanciesNumberHighSchool: 'Número de asientos para escuela secundaria',
    form: 'Formulario',
    public: 'Pública',
    particular: 'Privada',
    schoolData: 'Datos de la escuela',
    schoolInstitutionData: 'Datos de la Institución/escuela',
    institutionName: 'Nombre de la Institución / escuela',
    institutionNamePlaceholder: 'Ingrese el nombre de la institución / escuela',
    institutionResponsibleName: 'Nombre del responsable de la institución / escuela',
    institutionResponsibleNamePlaceholder: 'Ingrese el nombre de la persona a cargo de la institución / escuela',
    educationalInstitutionCategory: 'Categoría de educación',
    private: 'Privada',
    emailContactPlaceholder: 'Ingrese el correo electrónico de contacto',
    next: 'Seguiente',
    address: 'Dirección',
    zipCode: 'Código postal',
    uf: 'UF',
    number: 'Número',
    numberOptional: 'Número (Opcional)',
    numberPlaceholder: 'Ej: 274',
    complement: 'Complemento',
    complementOptional: 'Complemento (Opcional)',
    complementPlaceholder: 'Ej: Junto al supermercado',
    back: 'Regresar',
    site: 'Sítio',
    sitePlaceholder: 'Ingrese la URL del sitio web',
    fairCoordenatorName: 'Nombre del coordinador de la feria',
    fairCoordenatorNamePlaceholder: 'Ingrese el nombre del coordinador de la feria',
    currentFairFairTypePlaceholder: 'Ej: escuela',
    currentFair: 'Feria actual',
    lastFairEdition: 'Última edición de la feria',
    lastFairEditionData: 'Datos de la última edición de la feria afiliada',
    fairStatusChange: 'Cambio de estado de la feria',
    fairStatusChangeWarning: '¡Aviso! ¡Estás cambiando el estado activo / inactivo de esta edición de la feria! Al activarlo, habilita la recepción de suscripciones para esta edición. Al desactivar, está desactivando la recepción de suscripciones para esa edición.',
    affiliationRequestModalSendFormTitle: 'Tudo pronto para enviar o formulário de solicitação de afiliação?',
    affiliationRequestModalSendFormText: 'Ha llegado al final de completar su solicitud de proyecto. Te aconsejamos que revises tu información, porque cuando envíes tu solicitud ya no será posible modificarla.',
    formSelectionRequestModalSendFormTitle: 'Listo para enviar el formulario de solicitud?',
    formSelectionRequestModalSendFormText: 'Ha llegado al final de completar la solicitud de su proyecto. Le recomendamos que revise su información, porque cuando envíe su solicitud, ya no será posible cambiarla (Excepto el resumen del proyecto, que se puede cambiar hasta el final del plazo de solicitud).',
    yesContinue: 'Sí, continuar',
    noCancel: 'No, cancelar',
    failedToSendEmail: 'No se pudo enviar el correo electrónico',
    regulament: 'Regulación',
    regulamentLink: 'https://www.mostratec.com.br/es/regulacion-mostratec/',
    enter: 'Entrar',
    dataGeneral: 'Dados generales',
    vacanciesDetails: 'Vagas',
    areYouSureTitle: 'ADVERTENCIA: ¿Estás seguro de esto?',
    areYouSureText: 'Al confirmar este mensaje, se eliminarán todos los datos de participación de la feria afiliada y se desactivará la feria de Mostratec.',
    weakPassword: 'Contraseña debil!',
    passwordsDontMatch: '¡Las contraseñas ingresadas no coinciden!',
    invalidEmail: '¡Correo electrónico inválido!',
    invalidZipCode: 'Código postal no válido!',
    invalidEditionYear: 'El año de inicio de la feria debe ser mayor o igual que el año de su edición',
    dateError: 'La fecha de inicio debe ser menor o igual a la de finalización!',
    nextFairError: 'La fecha de la próxima edición de la feria debe ser mayor que la fecha actual.',
    lastFairError: 'La fecha de la última edición de la feria debe ser menor que la fecha actual.',
    levelOfEducationError: 'Marque al menos un nivel de educación',
    invalidDateFormat: 'Formato de fecha no válido',
    removeContact: 'Al confirmar este mensaje, se eliminarán todos los datos de contacto.',
    sendEMailFairText: 'Al confirmar este mensaje, se enviará un correo electrónico a esta feria afiliada con el resultado del análisis.',
    submit: 'enviar',
    lastFairEdStart: 'Comienzo',
    lastFairEdEnd: 'Final',
    calendarLocale: 'es',
    registrationFailedIcAlreadyExists: 'El registro falló, el usuario ya existe. Verifique el código de identificación ingresado',
    registrationFailedEmailAlreadyExists: 'El registro falló, el usuario ya existe. Verifique el correo electrónico ingresado',
    registrationFailedCpfAlreadyExists: 'El registro falló, el usuario ya existe. Verifique el CPF/CNPJ ingresado',
    exit: 'Salir',
    welcomeHomeHeader1: 'Para participar en la feria ',
    welcomeHomeHeader2: ', seleccione por las opciones a continuación y realice su preinscripción, donde validaremos sus datos para continuar en el proceso deseado.',
    welcomeHomePaymentInfo1: '¡La inscripción es gratuita y solo pagará la cuota de participación si es aceptado!',
    welcomeHomePaymentInfo2: 'También tenemos exenciones, si es necesario.',
    fairAffiliation: 'Afiliación de feria',
    welcomeHomeFairAffiliationText: 'Si representas a alguna feria escolar, estatal, nacional o internacional y eres un entusiasta de la ciencia en el país y tienes buenos proyectos, ¡consulta y afilia tu feria a Mostratec!',
    subscribeNow: '¡Suscríbase ahora!',
    projectRegistration: 'Inscripción del proyecto',
    affiliatedFairSingle: 'Feria Afiliada',
    welcomeHomeProjectRegistrationText1: 'Para proyectos acreditados por una',
    welcomeHomeProjectRegistrationText2: 'o para presentación en el',
    welcomeHomeProjectRegistrationText3: ': Pronto será el momento de completar tu registro, ¡espera!',
    moreInformation: 'Para más información visite',
    illustrationsBy: 'Ilustraciones:',
    preRegistration: 'Preinscripción',
    inputName: 'Ingrese su nombre',
    inputFairName: 'Ingrese el nombre de la feria',
    inputCnpjCpf: 'Ingrese el CNPJ o CPF de su institución',
    inputEmailToConfirmRegistration: 'Ingrese el correo electrónico para la confirmación del registro previo',
    inputPasswordToLogin: 'Cree una contraseña para iniciar sesión en el sistema',
    passwordConfirm: 'Confirma la contraseña',
    warningAllFieldsAreObligatory: 'Se deben completar todos los campos.',
    warningAllRequiredFieldsAreObligatory: 'Se deben completar todos los campos obligatorios.',
    warningEmailAlreadyUsed: 'No se pudo registrar, el correo electrónico ya está en uso',
    warningCnjpCpfIdAlreadyUsed: 'El registro falló, cpf/cnpj/número de identificación ya está en uso',
    warningTryLater: 'Error, inténtalo de nuevo más tarde.',
    welcomeProjectHeader1: 'Antes de continuar, cuéntanos...',
    welcomeProjectHeader2: '¿Con cuál de los siguientes tipos de inscripción de proyectos piensa continuar?',
    //welcomeProjectRegistrationBySelectionProcess: 'Para aquellos que ya tienen un proyecto científico y están interesados ​​en pasar por el proceso de selección para participar en la feria de forma independiente.',
    welcomeProjectRegistrationBySelectionProcess: 'Para proyectos que quieran pasar por el proceso de selección para participar en la Mostratec de modo independiente.',
    byAffiliatedFair: 'Por Feria Afiliada',
    //welcomeProjectRegistrationByAffiliatedFair: 'Para quienes deseen registrarse y participar en la feria a través de las Ferias Afiliadas inscritas.',
    welcomeProjectRegistrationByAffiliatedFair: 'Para proyectos acreditados a través de una feria afiliada a Mostratec.',
    teachingLevels: 'Niveles de docencia contemplados:',
    highSchoolTechnical: 'Enseñanza secundaria / técnica',
    elementarySchool: 'Enseñanza primaria',
    childEducation: 'Educación infantil',
    responsibleStudentName: 'Nombre del estudiante responsable',
    inputTheResponsibleStudentName: 'Ingrese el nombre del alumno responsable del proyecto',
    projectRegistrationWarningCnjpCpfIdAlreadyUsed: 'El registro falló, el usuario ya existe. Verifique su correo electrónico y CPF / CNPJ o número de identificación (si es extranjero)',
    registrationFailedAlreadyExistsCheckBoth: 'El registro falló, ¡el usuario ya existe! Verifique el correo electrónico y CPF ingresados',
    selectFairName: 'Seleccione el nombre de la feria',
    inputYourCPF: 'Ingrese su CPF',
    vacanciesRequest: 'Solicitud de vacantes/número de proyectos solicitados', // 'Solicitud de Vacantes',
    vacanciesRequestResponse: 'Vacantes concedidas / Aceptación de proyectos',
    panelFairVacanciesWaitWillBeAnalyzed: 'Espere. Nuestro sistema ha recibido su solicitud de vacantes y se analizará a continuación.',
    panelFairVacanciesWaitUnderAnalysis: 'Espere. Su solicitud de vacantes está siendo analizada y en breve nos comunicaremos con usted con una respuesta por correo electrónico.',
    panelFairVacanciesUnsignedSubscription: 'Su suscripción aún no se ha finalizado. Proceda a su registro haciendo',
    clickingHere: 'clic aquí',
    affiliationRequest: 'Solicitar afiliación',
    thereIsNoData: 'No hay datos',
    fairSelect: 'Seleccione una feria',
    panelFairVacanciesDenied: 'Gracias por su solicitud, pero no fue posible otorgar lugares para la membresía en esta edición.',
    reason: 'Razón',
    fairPeriodicity: 'Periodicidad de la feria',
    periodOfLastFair: 'Periodo de la última feria',
    periodForNextFair: 'Periodo de realización de la próxima feria',
    to: 'a',
    nextFairDateIsConfirmated: '¿Está confirmada la fecha de la próxima feria?',
    yes: 'Sí',
    no: 'No',
    nextFairFormat: 'Formato de la próxima feria',
    editionsFairNumber: 'Número de ediciones de la feria',
    doesTheFairHaveCrc: '¿Tiene la feria un Comité de Revisión Científica (CRC)?',
    educationLevelsInTheFair: 'Niveles de educación que participan en la feria:',
    childEducationCompleted: 'Educación infantil',
    childEducationCompleted2024: 'Educación infantil - Preescolar (estudiantes de 4 a 6 años)',
    elementarySchoolCompleted: 'Escuela primaria (1er a 9er año)',
    highSchoolOrTechnicalCompleted: 'Escuela secundaria/técnica',
    childEducationategory0: 'Educación Infantil - Preescolar',
    elementarySchoolCategory1: 'Escuela primaria - Categoría 1 (1er a 3er año)',
    elementarySchoolCategory2: 'Escuela primaria - Categoría 2 (4er a 6er año)',
    elementarySchoolCategory3: 'Escuela primaria - Categoría 3 (7er a 9er año)',
    // elementarySchoolCategory4: 'NO',
    elementarySchoolCategoryEja: 'Escuela primaria - Categoría 4 Educación de Jóvenes y Adultos EJA (cualquier año)',
    highSchoolAndOrTechnical: 'Educación técnica de escuela secundaria y/o secundaria',
    universityEducation: 'Enseñanza superior',
    users: 'Usuarios',
    profileAccess: 'Perfiles de acceso',
    userSettings: 'Ajustes de usuario',
    status: 'Estado',
    school: 'Colegio',
    confirmationEmailSent: '¡El correo electrónico de confirmación se envió correctamente! Para finalizar su registro acceda a la casilla de correo electrónico informada en el formulario y confirme los datos facilitados.',
    affiliatedFairStudent: 'Estudiante de la feria afiliada',
    lastEdition: 'Última edición',
    aboutFair: 'Sobre la feria',
    aboutSchool: 'Sobre la escuela',
    responsibilityTerm: 'Termino de Responsabilidad',
    projectsNumber: 'Numero de proyectos',
    teachersNumber: 'Numero de profesores',
    schoolsNumber: 'Numero de escuelas',
    male: 'masculino',
    female: 'femenino',
    totalStudents: 'Total de estudiantes',
    projectsWithOneStudent: 'Número de proyectos con 1 alumno',
    projectsWithTwoOrThreeStudent: 'Número de proyectos con 2 o 3 estudiantes',
    generalInformation: 'Informaciones generales',
    schoolNumberThatParticipatedOnFair: 'Número de escuelas que participaron en su feria',
    otherAffiliatedFairParticipe: 'Participan otras ferias afiliadas',
    publicSchoolNumber: 'Número de escuelas públicas',
    privateSchoolNumber: 'Número de escuelas privadas',
    fairAffiliatesNumber: 'Número de afiliados de la feria',
    responsibilityTermParagraph1: 'Soy consciente de los datos facilitados en el formulario de afiliación, y son acordes con la feria realizada.',
    //responsibilityTermParagraph1: 'Tengo conocimiento y confirmo la veracidad de los datos informados en el formulario de inscripción.',
    responsibilityTermParagraph2: 'Asumo el compromiso de acreditar el(los) proyecto(s) según los criterios de participación y la normativa de Mostratec y/o Mostratec Júnior.',
    responsibilityTermParagraph3: 'Soy consciente de que si la feria selecciona un proyecto que no está de acuerdo con lo anterior, el proyecto no será aceptado y la feria afiliada puede perder el derecho a renovar la afiliación en el año siguiente.',
    resonsablePersonForFillingIn: 'Nombre de la persona responsable de completar',
    positionFunctionAtFair: 'Cargo / Función en la feria',
    vacanciesListed: 'Vacantes inscritas',
    vacanciesRequested: 'Vacantes solicitadas',
    vacanciesGranted: 'Vacantes concedidas',
    vacanciesExempt: 'Vacantes exentas',
    researchArea: 'Áreas de investigación',
    disabled: 'Desactivado',
    deactivatedRequest: 'Tu solicitud ha sido desactivada.',
    requestDate: 'Fecha de solicitud',
    lastUpdate: 'Última actualización',
    registrationInProgress: 'Inscripción en curso',
    considerAllFieldObligatory: 'Considere todos los campos obligatorios, a menos que el texto (opcional) aparezca en el título.',
    schoolEmail: 'Correo electrónico de la institución / escuela',
    schoolEmailPlaceHolder: 'Ingrese el correo electrónico de la escuela',
    aboutYourFair: 'Sobre la última edición de la feria afiliada',
    generalInfos: 'Información general',
    vacanciesRequestsInPlural: 'Solicitudes de vacantes',
    checkTheData: '¡Consulta los datos proporcionados!',
    inputInstitutionCepPlaceholder: 'Ingrese el código postal de su institución',
    inputCepPlaceholder: 'Ingrese el código postal',
    addressExample: 'Calle, Avenida...',
    checkTheCep: 'Por favor ingrese un código postal válido',
    contactOneInformation: 'La información del contacto 1 se utilizará para notificaciones sobre la feria Mostratec',
    inputContactEmail: 'Ingrese el correo electrónico de contacto',
    main: '(Principal)',
    addContact: 'Agregar contacto',
    emailsMustBeDifferent: '¡Los correos electrónicos ingresados ​​deben ser diferentes!',
    internationalFairType: 'Internacional',
    schoolFairType: 'Escuela (solo participan proyectos escolares)',
    multiSchoolFairType: 'Multiescuela (participan proyectos de diferentes escuelas, que pueden ser de la misma red o de diferentes redes, incluso de diferentes ciudades);',
    townFairType: 'Municipal (participan exclusivamente proyectos de la red de educación municipal);',
    townFairType2: 'Municipal - otras redes (participa más de una red educativa: municipal y/o estatal y/o privada)',
    regionalFairType: 'Regional',
    stateFairType: 'Estatal',
    nationalFairType: 'Nacional',
    whatsIsTheNextFairDate: '¿Es la fecha de la próxima feria?',
    confirmated: 'Confirmada',
    forecastBecause: 'Previsión, ya que la fecha aún no está confirmada',
    editionsFairNumberPlaceholder: 'Ingrese el número de ediciones',
    checkTheEducationalLevels: 'Marque los niveles de educación que participan en su feria:',
    specifyTheFollowingData: 'Proporcione la siguiente información de acuerdo con el(los) nivel(es) educativo(s) participante(s) en su feria. Si su feria no incluye alguno de los niveles educativos mencionados a continuación, no es necesario completar la información.',
    vacanciesNumberRequested: 'El número de vacantes solicitado aquí no necesariamente será el número de proyectos que la feria podrá acreditar. Para saber cuántos proyectos la feria podrá recomendar para participar en MOSTRATEC, debes esperar la respuesta de la solicitud.', // 'El número de vacantes solicitadas no será necesariamente el número de vacantes otorgadas.',
    ifYourFairDoesNotIntendCoverAllTypes: 'Si su feria no pretende cubrir todos los tipos de educación, no es necesario completar todos los campos.',
    howManyProjects: 'Cuantos proyectos?',
    howManyProjectsAbout: 'Cuantos proyectos:',
    projectsNumberPlaceholder: 'Ingrese el número de proyectos',
    schoolsNumberPlaceholder: 'Ingrese el número de escuelas',
    teachersNumberPlaceholder: 'Ingrese el número de profesores',
    studentsNumberPlaceholder: 'Ingrese el número de estudiantes',
    projectsWithOneStudentPlaceholder: 'Ingrese el número de proyectos con 1 alumno',
    projectsWithTwoOrThreeStudentPaceholder: 'Ingrese el número de proyectos con 2 o 3 estudiantes',
    oneLevelIsNecessary: 'Es necesario proporcionar datos para al menos un nivel de educación.',
    allFieldsSelectedMustBeCompleted: 'Todos los campos del nivel seleccionado deben completarse.',
    thereAreExistingSolicitations: '¡Ya existen solicitudes abiertas!',
    yearly: 'Anual',
    biennial: 'Bienal',
    triennial: 'Trienal',
    totalOfSchoolsInYourFair: 'Número total de escuelas que participaron en su feria',
    DidOthersAffiliatedFairsParticipate: '¿Participaron otras ferias afiliadas en su feria??',
    didOthersAffiliatedFairsParticipateAbout: 'Participaron otras ferias afiliadas',
    affiliatedFairsNumber: 'Número de ferias afiliadas',
    affiliatedFairsNumberPlaceholder: 'Ingrese el número de ferias afiliadas',
    vacanciesNumber: 'Número de vacantes/Número de proyectos',
    vacanciesNumberPlaceholder: 'Ingrese la cantidad deseada',
    tickTheResearchArea: 'Marque el área de investigación (Opcional)',
    biology: 'Biología',
    biologyAndChemistry: 'Biología y Química',
    sciences: 'Ciencias (ambientales, animales y vegetales)',
    computerScience: 'Ciencias de la Computación',
    planetaryAndEarthSciences: 'Ciencias Planetarias y de la Tierra, Matemáticas y Física',
    socialSciencesBehaviorArt: 'Ciencias Sociales, Comportamiento y Arte',
    engineering: 'Ingeniería (eléctrica, electrónica, mecánica)',
    engineeringAndMaterials: 'Ingeniería y Materiales',
    environmentalManagement: 'Gestión ambiental',
    medicineAndHealth: 'Medicina y salud',
    otherResearchArea: '¿Otro? Ingrese el área de investigación',
    categoryDesired: 'Informar la(s) categoría(s) en la(s) que su feria le gustaría participar y la cantidad de proyectos pretendidos', // 'Informar la(s) categoría(s) en las que participará su feria y el número de vacantes deseadas',
    makeSureToFillIn: 'Asegúrate de completar los campos.',
    readTheFollowingTerm: 'Lea el siguiente término de responsabilidad',
    readAndAgree: 'Yo leo y estoy de acuerdo',
    resonsablePersonForFillingInPlaceholder: 'Ingrese el nombre de la persona a cargo',
    positionFunctionAtFairPlaceholder: 'Insertar el puesto/función en la feria',
    sendForm: 'Enviar Formulario',
    makeSureToReadAndAgree: 'Asegúrese de leer y aceptar antes de continuar.',
    requestSentSuccessfully: '¡Solicitud enviada con éxito! ¡Se le redirigirá al Panel de la feria para que siga su solicitud!',
    errorSendingForm: 'Error al enviar el formulario',
    emailsAlreadyInUseChangeThemOnForm3: 'Los correos electrónicos de contacto que ya están en uso en otra solicitud, cámbielos en el formulario 3 - Contactos',
    requestSentSuccessfullyTitle: 'Solicitud enviada con éxito',
    VacanciesGrantedMustBeProvided: 'Se deben proporcionar todos los campos vacantes otorgados',
    VacanciesGrantedValuesNotValid: 'Los valores de los vacantes otorgados no son válidos',
    requestDisabled: 'Solicitud deshabilitada',
    biochemistryAndChemistry: 'Bioquímica y Química',
    other: 'Otro',
    'inscricao em andamento': 'Inscripción en curso',
    'pendente de analise': 'Revisión pendiente',
    'em analise': 'En análisis',
    'aguardando resposta': 'Esperando respuesta',
    'resposta enviada': 'Respuesta enviada',
    whatIsYourEducationalLevel: 'Cual es tu nivel educativo?',
    confirmYourEducationalLevel: 'Confirme su nivel educativo para continuar con el formulario de solicitud de su proyecto en la edición activa.',
    levelsOfEducational: 'Nivel de educación',
    confirmAndContinue: 'Confirmar y continuar',
    levelsOfEducationalDescription: 'Las inscripciones del tipo Proceso de selección están destinadas únicamente a la educación secundaria y técnica. Confirma si perteneces a este nivel educativo para proceder con tu postulación a Mostratec 2021.',
    activeEditionForSubscription: 'Edición activa para suscripción',
    fillOutTheFormBelow: 'Complete el siguiente formulario de acuerdo con su tipo de registro para registrar su proyecto.',
    toStartButton: 'PARA COMENZAR',
    automaticSavingModalText1: 'El sistema tiene guardado automático, cada vez que presione el botón "siguiente" en el formulario, la página anterior se guardará automáticamente.',
    automaticSavingModalText2: 'Le garantizamos que su información se mantiene de forma segura. Para reanudar el llenado de la solicitud, simplemente haga clic en "sí, continuar".',
    autoSave: 'Guardado automático',
    informWhichSchoolYouAreEnrolled: 'Informe en qué escuela / institución está / estuvo inscrito durante el desarrollo del proyecto o registre una nueva, si la suya no está en el sistema.',
    nameOfSchoolOrInstitution: 'Nombre de la escuela / institución',
    yourSchoolIsNotInTheSystem: '¿Tu escuela / institución no está en el sistema?',
    newInstitution: 'Nueva Institución',
    institutionNameSingle: 'Nombre de la Institución',
    institutionNameSinglePlaceholder: 'Ingrese el nombre de la institución',
    educationalInstitutionCategoryCompleted: 'Categoría de institución educativa',
    institutionPersonInCharge: 'Nombre del responsable de la institución',
    institutionPersonInChargePlaceholder: 'Ingrese el responsable de la institución',
    IfThereIsNoCNPJLinkedToTheSchool: 'Si no hay un CNPJ vinculado a la escuela, ingrese el CPF de la persona responsable de la institución en el campo anterior.',
    aboutStudents: 'Acerca de los estudiantes',
    aboutAdvisors: 'Acerca de los asesores',
    othersInvolved: 'Otros involucrados',
    researchPlan: 'Plan de investigación',
    summaryForTheResearcher: 'Resumen para el investigador',
    fileUpload: 'Subir archivo',
    buttonContinue: 'Prosseguir',
    tc_titulo: 'Formulario de Consentimiento para el Tratamiento de Datos Personales en cumplimiento de la Ley Brasileña Nº 13.709, del 14 de agosto de 2018, Ley General de Protección de Datos Personales - LGPD.',
    tc_aviso: 'Lea atentamente todos los elementos a continuación antes de continuar.',
    tc_p1: 'Este formulario cumple con la Política de Datos Personales de la Fundación Escuela Técnica Liberato Salzano Vieira da Cunha y la Ley General de Protección de Datos Personales (LGPD).',
    tc_p2: 'El controlador de datos (Fundación Liberato) es responsable de cualquier dato que se procese.',
    tc_p3: 'Declaro estar consciente de mis DERECHOS respecto a la recolección y tratamiento de datos personales según la Ley General de Protección de Datos Personales (LGPD) Nº 13.709, del 14 de agosto de 2018, y expreso MI CONSENTIMIENTO para la recolección y tratamiento de DATOS en este formulario.',
    tc_p4: 'Declaro estar consciente de que tendré ACCESO a los datos personales recolectados en cualquier momento y que puedo solicitar una copia de estos datos; que puedo RECTIFICAR mis datos personales recolectados en cualquier momento sin restricciones; que puedo solicitar la ELIMINACIÓN de los datos recolectados aquí en cualquier momento; que puedo impugnar el procesamiento de los datos personales recolectados aquí, por motivos relacionados con situaciones diversas y/o particulares; que puedo RETIRAR MI CONSENTIMIENTO en cualquier momento; que puedo SOLICITAR LA PORTABILIDAD DE DATOS y que me sean devueltos.',
    tc_p5: 'Concesión de publicidad:',
    tc_p6: 'a) Doy permiso al Comité Central de la MOSTRATEC para utilizar la información proporcionada (nombres, títulos de proyectos, institución educativa, ferias afiliadas, resúmenes), fotos, videos, folletos, entre otros, para la divulgación de la MOSTRATEC;',
    tc_p7: 'b) Los medios de comunicación (escritos, hablados y visuales) están autorizados a utilizar datos o imágenes con el fin de promocionar el evento;',
    tc_p8: 'c) Los finalistas se comprometen a colaborar con los medios de comunicación y entidades relacionadas para proporcionar información para la promoción del evento y sus proyectos;',
    tc_p9: 'd) Los finalistas dan permiso al Comité Central de la MOSTRATEC para compartir datos, como nombres, contactos, instituciones educativas, títulos y resúmenes de proyectos con fines de premiación, acreditaciones en otras ferias, eventos, agencias de financiamiento a la investigación y entre las instituciones asociadas y coorganizadoras de MOSTRATEC;',
    tc_p10: 'e) Los responsables de las ferias afiliadas dan permiso al Comité Central de la MOSTRATEC para la divulgación de los nombres de las ferias y sus instituciones organizadoras, sus responsables y sus respectivos contactos, con el fin de promover la investigación entre las instituciones asociadas y coorganizadoras de MOSTRATEC.',
    linkLei13709: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm',
    DeclareAsRead: 'Advertencia: Por favor, declare como leídos lo Formulario de Consentimiento para el Tratamiento de Datos Personales de LGPD antes de proceder.',
    thereAreNoResearchAvaliable: 'No existen áreas de investigación disponibles para este nivel de educación.',
    importantTitle: '¡Importante!',
    revalidationByAdminText: 'Después de su confirmación, la información del análisis anterior se perderá y será necesaria otra evaluación. Quieres continuar?',
    profile: 'Perfil',
    edition: 'Edición',
    notAccept: 'No acepto',
    termLGPD: 'Término LGPD',
    noResults: 'No hay resultados',
    neighborhoodPlaceholder: 'entrar al barrio',
    statePlaceholder: 'Entrar al estado',
    cityPlaceholder: 'Entrar en la ciudad',
    projectName: 'Título del proyecto',
    enterTheProjectName: 'Ingrese el nombre del proyecto',
    checkYourProjectResearchArea: 'Consulta el área de investigación de tu proyecto',
    art: 'Artes',
    bio: 'Biología',
    eduPedago: 'Educación y pedagogía',
    eletronicInfoRobo: 'Electrónica, informática y robótica',
    enge: 'Ingenieria',
    filoso: 'Filosofía',
    fis: 'Física',
    geo: 'Geografía',
    his: 'Historia',
    incAcessTec: 'Inclusión, accesibilidad y tecnología',
    qui: 'Química',
    lingPortLingStrangLit: 'Lengua portuguesa, lengua extranjera y literatura',
    mat: 'Matemáticas',
    meioAmb: 'Medio ambiente',
    saud: 'Salud',
    socio: 'Sociología',
    enterTheOtherSubarea: 'Entrar en la otra subárea',
    enterTheSummary: 'Ingrese el resumen',
    enterTheThema: 'Ingrese el tema',
    summary: 'Resumen',
    theme: 'Tema',
    registrationClosed: 'Registro cerrado',
    caseYouMissedTheOpportunity: 'En caso de que haya perdido la oportunidad, ¡esperamos su inscripción en la próxima edición de Mostratec!',
    isTimeToRegister: '¡Es hora de registrarse!',
    informAboutTheAdvisors: 'Informar a continuación los datos del asesor y co-asesor, en su caso.',
    advisor: 'Asesor',
    coAdvisor: 'Co-asesor',
    graduation: 'Graduado',
    actingDiscipline: 'Disciplina de actuación',
    enterTheGraduation: 'Ingresa la graduación',
    enterTheActingDiscipline: 'Entrar en la disciplina de actuación',
    haveYouParticipatedAnyOtherMostratec: '¿Has participado en alguna otra edición de Mostratec?',
    disableCoadvisor: '¿Deshabilitar co-asesor?',
    disableCoadvisorModalP1: '¿Está seguro de que desea deshabilitar el registro de co-asesor para este proyecto? Tras la inactivación, enviará su proyecto',
    whitoutCoadvisor: 'sin un co-asesor.',
    disableCoadvisorModalP2: 'Para habilitarlo nuevamente, simplemente reactívelo a través de lo',
    caseThereAreOthersInvolvedDescription: 'Los campos a continuación deben ser completados si hay otras personas involucradas en el proyecto que participarán en MOSTRATEC, pero no han desarrollado el proyecto. Ejemplo: jefe de delegación, padres, alumno observador, etc.',
    caseThereAreOthersInvolved: '¿Hay otras personas involucradas en este proyecto?',
    addNewInvolved: 'Agregar nuevos involucrados',
    person: 'Persona',
    designation: 'Designacion',
    enterDesignation: 'Ingrese la designación',
    designationExample: 'Ejemplo: director, jefe de delegación, padres o tutores, etc.',
    enterTheSpecializedService: 'Ingrese al servicio especializado',
    disablePeopleInvolved: '¿Deshabilitar a las personas involucradas?',
    disablePeopleInvolvedParagraph: '¿Está seguro de que desea deshabilitar el registro de otras personas involucradas en este proyecto? Al inactivar, perderá el contenido ingresado en sus campos y esta acción no se puede deshacer.',
    projectAbstractIntro: 'El resumen será remitido a los evaluadores y publicado en la web de Mostratec y deberá presentar:',
    projectAbstractIntroA: 'a) objetivo del proyecto o del experimento realizado;',
    projectAbstractIntroB: 'b) los procedimientos utilizados;',
    projectAbstractIntroC: 'c) observaciones / datos / resultados (brevemente);',
    projectAbstractIntroD: 'd) conclusiones / aplicaciones;',
    projectAbstractIntroE: 'e) las palabras clave.',
    enterTheProjectAbstract: 'Ingrese el resumen del proyecto',
    youCanEditTheAbstract: 'Podrá editar el resumen de su proyecto después de enviar su solicitud hasta que se cierre la fecha límite.',
    reviewProjectData: 'Revise la información clave de su proyecto de investigación. Si alguno de los datos a continuación es incorrecto, no dude en editarlo entre los pasos anteriores del proceso de solicitud. Recuerda que una vez envíes la solicitud de registro, no podrás cambiar los datos.',
    students: 'Estudiantes',
    researchProjectStartDate: 'Fecha de inicio del proyecto de investigación',
    plannedEndDate: 'Fecha de finalización planificada',
    willYourProjectBeContinued: '¿Continuará su proyecto?',
    plannedDateForStartingExperimentation: 'Fecha en que la etapa de continuidad de la investigación inició la experimentación/recopilación de datos',
    willYouConductInLaboratory: '¿Realizó el paso de continuidad de la investigación en un laboratorio, instituto o industria regulada?',
    willTheContinuityIncludeNewStudents: '¿La etapa de continuación del proyecto incluyó nuevos estudiantes?',
    willTheContinuityIncludeNewResearch: '¿La etapa de continuidad del proyecto tuvo otra área o subárea de investigación?',
    researchInstituitionIndustry: 'Institución de investigación o industria regulada',
    telephone: 'Teléfono',
    locationsWhereTheResearchWillBeConducted: 'Ubicaciones donde se llevará a cabo la investigación',
    field: 'Campo',
    atHome: 'En casa',
    itemsThatApplyToYourResearchTrials: 'Elementos que se aplican a sus ensayos de investigación',
    humanBeings: 'Seres humanos',
    vertebrateAnimals: 'Animales vertebrados',
    potentiallyHazardousBiologicalAgents: 'Agentes biológicos potencialmente peligrosos',
    hazardousChemicalSubstances: 'Sustancias, actividades o equipos químicos peligrosos',
    substancesControlledByLaw: 'Sustancias controladas por la ley',
    addNewStudent: 'Agregar nuevo estudiante',
    disableStudentInvolved: '¿Deshabilitar al estudiante involucrado?',
    disableStudentInvolvedInfo: '¿Está seguro de que desea deshabilitar el registro de otros estudiantes involucrados en este proyecto? Al inactivar, perderá el contenido ingresado en sus campos y esta acción no se puede deshacer.',
    filesMustBeSent: 'Los archivos deben enviarse antes del final del registro.',
    requiredFiles: 'Archivos requeridos',
    downloadForms: 'descargar formularios',
    fileFormatMustBePDF: 'El formato de archivo debe estar en PDF',
    workPresentation: 'Presentación del trabajo',
    fileFormatMustBeMP4: 'El formato de archivo debe ser MP4',
    dragAndDropTheFile: 'Arrastre y suelte el archivo o',
    select: 'seleccione',
    invalidFile: 'Archivo inválido. Vuelve a intentarlo con el formato correcto',
    specificFiles: 'Archivos específicos',
    filesRequestBelowInfo: 'Los archivos solicitados a continuación, corresponden a su investigación y fueron definidos de acuerdo con sus respuestas en la página.',
    continuityProject: 'Proyecto de continuidad',
    pottentialyDangerousSubstances: 'Sustancias biológicas potencialmente peligrosas',
    riskAssessment: 'Evaluación de riesgos',
    qualifiedScientist: 'Científico calificado',
    projectContinuityFileFormat: 'Para la continuidad del proyecto. El formato de archivo debe estar en PDF',
    vertebrateAnimalsFileFormat: 'Para investigaciones que involucren animales vertebrados, agregue el Formulario 5. El formato de archivo debe estar en PDF',
    humanBeingsFileFormat: 'Para investigaciones que involucren seres humanos, agregue el Formulario 4. El formato de archivo debe estar en PDF',
    dangerousSubstancesFileFormat: 'Para la investigación con agentes biológicos potencialmente peligrosos, agregue la Forma 6A. El formato de archivo debe estar en PDF',
    animalTissueFileFormat: 'Para la investigación con tejidos de animales humanos o vertebrados, agregue la Forma 6B. El formato de archivo debe estar en PDF',
    instituitionFileFormat: 'Para la investigación en una institución de investigación o industria regulada, agregue el Formulario 1C. El formato de archivo debe estar en PDF',
    submitRegistration: 'Presentar la solicitud',
    ifYouChangeTheEducationalLevel: 'Advertencia: Si cambia el nivel de educación, perderá los datos previamente guardados en el formulario.',
    saveAndAdvance: 'Guardar y avanzar',
    selectEtc: 'Seleccione...',
    selectLevel: 'Seleccionar un nivel',
    confirmWhichOfOurAffiliatedFairsIsYou: 'Confirme cuál de nuestras ferias afiliadas solicitará la inscripción de su proyecto. Si su feria no aparece en nuestro sistema, póngase en contacto con ella.',
    fair: 'Feria',
    fairNamePlaceholder: 'Feria nombre',
    thereAreNoFairsToYourLevel: 'No hay ferias para tu nivel educativo',
    coexistenceContract: 'Contrato de convivencia',
    //coexistenceContractInfo: 'Tengo conocimiento y confirmo la veracidad de los datos informados en el formulario de inscripción.Tengo conocimiento de los datos informados en el formulario de inscripción, el cual está de acuerdo con la feria celebrada. Me comprometo a enviar el archivo del contrato de convivencia a Mostratec Júnior, ya sea de forma virtual o presencial.',
    coexistenceContractInfo: 'Tengo conocimiento y confirmo la veracidad de los datos informados en el formulario de inscripción.',
    howWillTheContractBeDelivered: '¿Cómo se entregará el contrato?',
    loading: 'Cargando...',
    resultsAndConclusions: 'Resultados y conclusiones',
    resultsAndConclusionsPlaceholder: 'Ingrese los resultados y las conclusiones de la investigación',
    resultsAndConclusionsTextInfo: 'Su propósito es demostrar lo descubierto o entendido por los autores a partir de la conclusión de la investigación.',
    researchProblem: 'Problema de investigación',
    projectCategory: 'Categoría de proyecto',
    neighborhood: 'Barrio',
    grade: 'Grado',
    childreanTShirt: 'Camiseta para niños',
    elementarySchoolCategory1Single: '1er a 3er año',
    elementarySchoolCategory2Single: '4er a 6er año',
    elementarySchoolCategory3Single: '7er a 9er año',
    maleTShirt: 'Masculina',
    sizeSix: '4-5 años - Talla 6',
    sizeEight: '5-7 años - Talla 8',
    sizeTen: '7-9 años - Talla 10',
    sizeTwelve: '11-12 años - Talla 12',
    sizeFourteen: '13-14 años - Talla 14',
    //-------------------------------------------------------
    tsShirt01: 'Infantil - Talla 8',
    tsShirt02: 'Infantil - Talla 10',
    tsShirt03: 'Infantil - Talla 14',
    tsShirt04: 'Adulto - Talla P',
    tsShirt05: 'Adulto - Talla M',
    tsShirt06: 'Adulto - Talla G',
    tsShirt07: 'Adulto - Talla GG',
    tsShirt08: 'Adulto - Talla EXG',
    //-------------------------------------------------------
    class: 'Clase',
    quartet: 'Cuarteto',
    nursery: 'Guardería',
    maternal: 'Materno',
    kindergarten: 'Preescolar',
    lessThan1Year: 'Menos de 1 año',
    OneTwoYears: '1 a 2 años',
    ThreeFourYears: '3 a 4 años',
    FiveSixYears: '5 a 6 anos',
    advisorIsMandatory: 'El asesor es obligatorio',
    landlineOrCellphone: 'Teléfono (fijo o celular)',
    studentResponsibleForPreRegistration: 'Estudiante responsable de preinscripción y registro de proyectos.',
    peopleInvolved: 'Personas involucradas',
    advisors: 'Asesores',
    waitingReply: 'Esperando respuesta',
    waitingEmailSent: 'Esperando que se envíe el correo electrónico',
    requestApproved: 'Proyecto revisado!',
    sendEmailDescription: 'Cuando desee notificar a los estudiantes sobre su estado, simplemente haga clic en “enviar correo electrónico” y un correo electrónico los guiará para acceder a la plataforma Mostratec y verificar su nuevo estado.',
    sendEmail: 'ENVIAR CORREO ELECTRÓNICO',
    emailSent: 'Correo electrónico enviado',
    emailSentStudents: '¡El correo electrónico de retorno a los estudiantes ha sido enviado con éxito!',
    statusUpdateAfterAnalysis: 'El estado de las solicitudes se actualizará de acuerdo con el análisis realizado.',
    presentationLink: 'Enlace de vídeo de presentación',
    replySentByEmail: 'Respuesta enviada por correo electrónico',
    sentEmailDescription: 'Proyecto revisado! El correo electrónico que notifica al estudiante (s) y al patrocinador de la feria afiliado sobre el estado ya ha sido enviado. Ahora el (los) alumno (s) y / o la Feria afiliada podrán acceder a la plataforma Mostratec, consultar su nuevo estado y gestionar las inscripciones.',
    choose: 'Seleccionar',
    all: 'todo',
    draft: 'Borrador',
    newStatus: 'Nuevo estado',
    registrationStatus: 'Estado de registro',
    noData: 'No hay datos!',
    succesfullySaved: 'Guardado exitosamente',
    inputValidUrl: 'Ingrese una URL válida',
    loadingMightTakeWhile: 'Cargando... Esto podría tomar tiempo',
    presentationVideoInformation: 'Puede cargar su video como un archivo o como un enlace de YouTube. Tienes hasta la fecha límite de registro para hacerlo.',
    documentsLimit100Videos300: 'Límite de tamaño de 100 MB para documentos y 300 MB para videos, cada archivo',
    videoFormatMustBeMP4: 'El formato de video debe ser MP4',
    admProjects: 'Panel administrativo',
    projectRanking: 'Ranking de proyectos',
    Indicators: 'Indicadores',
    highFinalists: 'Finalistas Ensino Médio',
    middleFinalists: 'Finalistas de secundaria',
    childishFinalists: 'Finalistas de jardín de infancia',
    mostratecOverview: 'Descripción general de Mostratec',
    subtitleRS: 'Proyectos presentados por Rio Grande do Sul',
    subtitleBR: 'Proyectos presentados por Brasil',
    subtitleEX: 'Proyectos registrados en el extranjero',
    publicSchools: 'Escuelas publicas',
    privateSchools: 'Escuelas privadas',
    NumberParticipatingProjects: 'Número de proyectos participantes:',
    selectStateBeforeSave: 'Seleccione un estado antes de guardar!',
    projectCode: 'Código de proyecto',
    projectApplicationPeriodClosed: '¡Se cerró el período de envío de proyectos!',
    errorLoadingInformation: 'Error al cargar la información',
    institutionSchoolPhone: 'Teléfono de la institución / escuela (solo números)',
    enterInstitutionSchoolPhone: 'Ingrese el número de teléfono (solo números)',
    saveAndContinue: 'Guardar y continuar',
    saveAndSend: 'Guardar y enviar',
    selectSituation: 'Seleccionar situación',
    reviewCanceled: 'Registro cancelado',
    identificationNumber: 'Número de identificación',
    initialRegisterIdentificationNumber: 'Ingrese su número de identificación',
    cpf: 'CPF',
    cpfStudent: 'Número de identificación del estudiante',
    checkYourProjectNewResearchSubarea: 'Consulta la nueva subárea de investigación de tu proyecto.',
    youCanADDTheVideo: 'Podrás agregar el enlace del video hasta el final del registro',
    orEnterTheYoutubeLink: 'O ingrese el enlace del video de YouTube en el campo a continuación.',
    presentationLink: 'Inserte el enlace al video de presentación del proyecto',
    projectSevenFileFormat: 'Para la continuidad del proyecto, agregue el Formulario 7. El formato de archivo debe estar en PDF',
    formRiskAssessment: 'Para la evaluación de riesgos, agregue el formulario 3. El formato de archivo debe estar en PDF',
    formQualifiedScientist: 'Para científico calificado, agregue el formulario 2. El formato de archivo debe estar en PDF',
    enterUserEmailToPasswordRecover: 'Informar al correo electrónico del usuario para que se lleve a cabo la recuperación de la contraseña. Después de hacer clic en "enviar", revise su bandeja de entrada.',
    enterRecoverEmail: 'Ingrese el correo de recuperación',
    confirmYourEmail: 'Confirme su correo electrónico',
    emailSuccessfullySent: 'E-mail enviado con éxito!',
    passwordRecoveryEmailSent: 'Correo electrónico de restablecimiento de contraseña enviado',
    passwordRecoveryEmailSuccessfullySentCheckEmailChangePassword: 'El correo electrónico de restablecimiento de contraseña se envió correctamente a su bandeja de entrada. Revise su correo electrónico y cambie su contraseña.',
    acceptanceRequired: 'Para utilizar la plataforma, debe aceptar lo',
    dataProtectionTerm: 'Formulario de Consentimiento para el Tratamiento de Datos Personales.',
    followTheLink: 'Haga clic en el enlace de abajo para continuar.',
    attention: '¡Atención!',
    term: 'Formulario de Consentimiento para el Tratamiento de Datos Personales',
    onlineRegistrationTerm: 'Suscripción en linea',
    mostratecCriteria: 'Criterios de participación en MOSTRATEC',
    subscriptionRegistration: 'Inscripciones',
    agreed: '"LEÍ Y ACEPTO"',
    participationCriteria: 'DESPUÉS DE LEER TODOS LOS CRITERIOS DE PARTICIPACIÓN A CONTINUACIÓN.',
    forStudent: 'Para el estudiante:',
    highEnrolled: 'a) estar inscrito en un curso de Educación Secundaria o Educación Profesional',
    levelHigh: 'Técnica de Secundaria;',
    yearsOld: 'b) tener al menos 14 años;',
    responsible: 'c) ir acompañado de un responsable, si es menor de edad;',
    submitProject: 'd) Enviar el proyecto dentro de los plazos establecidos por Mostratec;',
    techProject: 'e) participar solo con un proyecto científico o tecnológico.',
    schoolBasic: 'a) estar inscrito en escuela basica curricular;',
    projectDeadline: 'b) Enviar el proyecto dentro de los plazos establecidos por Mostratec;',
    onlyOneProject: 'd) participar solo con un proyecto científico o tecnológico.',
    hasAdvisors: 'a) tener un mentor (21 años o más);',
    rulesAccordance: 'estar preparado de acuerdo con las reglas de investigación científica de Mostratec',
    studyAreas: 'c) tener, como objeto de estudio, una de las áreas de conocimiento definidas',
    byMostratec: 'por Mostratec;',
    investigation: 'd) centrarse en una de las siguientes características: investigación y / o innovación;',
    twelveMonthProject: 'e) Presentar el trabajo continuo de 12 (doce) meses, iniciado en el año',
    precedingFair: 'anterior a la feria.',
    maxThreeStudents: 'f) ser desarrollado por un máximo de 3 alumnos.',
    publicity: 'Concesión publicitaria:',
    centralComission: 'a) Los finalistas dan permiso a la Comisión Central para utilizar información',
    photos: '(fotos, videos, carpetas, entre otros) para la divulgación MOSTRATEC',
    press: 'b) Las organizaciones de prensa (escritas, habladas y visuales) están autorizadas a ',
    publicizing: 'utilizar datos o imágenes con el fin de publicitar el evento.',
    colaborate: 'c) Los finalistas se comprometen a colaborar con la prensa y',
    informationPublicizing: 'afines para facilitar información con el fin de dar a conocer el evento y',
    yourProjects: 'sus proyectos.',
    namePermission: 'd) Los finalistas dan permiso a la Comisión Central para divulgar sus',
    awarding: 'nombres (y las respectivas instituciones educativas) con fines de adjudicación y',
    participatingWorks: 'para utilizar las cantidades de obras participantes con fines de acreditación',
    otherFairs: 'en otras ferias, eventos y con agencias de financiación de la investigación.',
    comments: 'Observaciones:',
    studentAuthory: '- enfatizamos que el proyecto debe ser escrito por el (los) estudiante (s)',
    middleLevel: 'de educación profesional de nivel secundario o técnico;',
    establishedCriteria: '- los proyectos deben, necesariamente, cumplir con los criterios establecidos',
    loseSpot: 'anteriormente, de lo contrario, pierden la vacante.',
    readDocument: 'Declaro que he leído, estoy consciente y estoy de acuerdo con los términos expuestos arriba. (respuesta obligatoria).',
    forProject: 'Para el proyecto:',
    EJA: 'Para estudiantes de educación primaria, EJA o educación infantil:',
    descriptionTheme: 'Describe el aspecto de la asignatura que quieres abordar, probar o desarrollar.',
    yae: 'EJA',
    fileFormatPDF: 'La extensión del archivo debe ser PDF. Podrás enviarlo después de concluir tu inscripción, si aún no lo tienes.',
    optionalLabel: '(Opcional)',
    azureMessageFail: 'Por favor, inténtelo de nuevo más tarde. El servicio no está disponible en este momento.',
    errorLabel: 'Error',
    errorDuringSaving: 'Error durante el guardado',
    successfullRequest: 'Solicitud enviada con éxito! ¡Serás redirigido a la página inicial de inscripción!',
    projectAlreadySaved: 'Proyecto ya guardado!',
    schoolSaved: 'Escuela ya guardado!',
    parentageOfResponsible: 'Parentesco del responsable',
    adultsName: 'Ingrese el nombre del adulto',
    insertParentage: 'Ingrese la afiliación de los padres',
    registrationType: 'Tipo de registro',
    researchAreaSingular: 'Área de búsqueda',
    enrollment: 'Inscripción',
    value: 'Valor',
    food: 'Alimentación',
    finalValue: 'Valor final',
    infoAboutTypePayment: '¿Cómo desea realizar el pago?',
    payOnAccreditation: 'Pagar en la acreditación',
    registrationFeeAndFood: 'Cuota de inscripción y Alimentación',
    nationalFormPaymentInfoL1: '',
    nationalFormPaymentInfoL2: '',
    nationalFormPaymentInfoL3: '',
    nationalFormPaymentInfoL4: '',
    foreignFormPaymentInfoL1: '',
    foreignFormPaymentInfoL2: '',
    foreignFormPaymentInfoL3: '',
    foreignFormPaymentInfoL4: '',
    registrationFee: 'Cuota de inscripción',
    foreign: 'Extranjero',
    noMeal: 'No hay comida',
    oneMeals: '1 comida',
    twoMeals: '2 comidas',
    threeMeals: '3 comidas',
    fourMeals: '4 comidas',
    fiveMeals: '5 comidas',
    sixMeals: '6 comidas',
    sevenMeals: '7 comidas',
    approvalPayment: 'el pago debe hacerse después de la aprobación',
    chooseSchoolType: 'Elige el tipo de escuela',
    studentMustBeSelected: 'Al menos un estudiante debe ser seleccionado',
    SelectNumberOfMeals: 'Seleccione el número de comidas',
    participationNotSelected: 'Participación no seleccionada',
    selectPaymentMethod: 'Seleccione el método de pago',
    discountPayment: 'El descuento no puede ser mayor que el monto total',
    saveInformation: 'Guardar',
    participateInThisProject: 'Sí, participo en este proyecto',
    alreadyParticipateInAnotherProject: 'Ya participo en otro proyecto',
    willNotParticipateInMostratec: 'No participaré en Mostratec',
    cannotPayMercadoPago: 'No se puede pagar con Mercado Pago, Total = BRL 0.00',
    finish: 'Finalizar',
    projects: 'Proyectos',
    placeholderSearchBar: 'Buscar por nombre del asignado, nombre del proyecto o ID del proyecto',
    buttonAllTickets: 'Imprimir todas las etiquetas',
    commitNote: 'Nota de compromiso (solo municipios)',
    rulesOfConduct: 'Reglas de conducta',
    alreadyInUse: 'Este número de identificación ya está en uso',
    warningForMultiple: 'ADVERTENCIA: Registre solo UN proyecto por sesión de navegador. Abrir más de una pestaña/ventana del navegador causará conflicto/mezcla de datos entre proyectos y hará inviable el registro.',
    cohabitationAgreementRequired: 'Se requiere cargar el contrato de convivencia.',
    sebraeTitle: 'La siguiente pregunta es solo para proyectos de residentes en Brasil',
    sebraeQuestion1: '',
    sebraeQuestion2: '',
    sebraeResponse0: 'Mi proyecto no se origina en Brasil',
    sebraeResponse1: '',
    sebraeResponse2: '',
    categoryFormSelection: 'Nivel de Educación/Categoría',
    paymentDateLimit: 'La fecha límite de pago a través de Mercado Pago es el día '
  }
}
